import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getColor } from 'akeneo-design-system';
import styled from 'styled-components';
import logoPath from '../../../asset/images/app-logo.svg';
import { AssociationForm } from './AssociationForm';

const Container = styled.div`
    flex: 1;
    text-align: center;
`;

const Logo = styled.img`
    height: 60px;
    margin-right: 50px;
`;

const PanelTitleContainer = styled.div`
    color: ${getColor('brand', 100)};
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    margin-top: 18px;
`;

const PanelDescriptionContainer = styled.div`
    flex: 1 1 auto;
    text-align: center;
    padding-top: 30px;
`;

export const AssociationLeftPanel = () => {
    const intl = useIntl();
    return (
        <Container>
            <Logo
                src={logoPath}
                alt={intl.formatMessage({
                    defaultMessage: 'Akeneo App for Shopify',
                    id: 'GDMAQe',
                })}
            />
            <PanelTitleContainer>
                <FormattedMessage
                    id='vmvVhu'
                    defaultMessage='You already have Akeneo PIM'
                />
            </PanelTitleContainer>
            <PanelDescriptionContainer>
                <FormattedMessage
                    id='imwm3a'
                    defaultMessage='To connect your Akeneo PIM to Shopify, simply type your PIM URL below {br}and click on "Associate".'
                    values={{ br: <br /> }}
                />
            </PanelDescriptionContainer>
            <AssociationForm />
        </Container>
    );
};
