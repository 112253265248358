import { FormattedMessage } from 'react-intl';
import { SynchronizationType } from '../model/SynchronizationType';

type Props = {
    type: SynchronizationType;
};

export const SynchronizationTypeLabel = ({ type }: Props) => {
    switch (type) {
        case SynchronizationType.Differential:
            return (
                <FormattedMessage id='Zz4crq' defaultMessage='Delta import' />
            );
        case SynchronizationType.Full:
            return (
                <FormattedMessage id='TTuQP0' defaultMessage='Full import' />
            );
    }
};
