import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
    route: string;
    children?: React.ReactNode;
};

export const StyledNavLink = styled(NavLink)`
    color: ${({ theme }) => theme.color.grey140};
    font-size: ${({ theme }) => theme.fontSize.big};
    margin: 0 30px;
    text-decoration: none;

    &.active {
        color: ${({ theme }) => theme.color.brand100};
        font-weight: bold;
    }
`;

export const NavigationLink = ({ route, children }: Props) => {
    return (
        <StyledNavLink
            className={({ isActive }) => (isActive ? 'active' : undefined)}
            to={route}
        >
            <>{children}</>
        </StyledNavLink>
    );
};
