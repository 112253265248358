import { apiFetch } from '../../../../util/apiFetch';

type SaveSecondaryLocales = {
    storeId: string;
    data: { [key: string]: string };
};

export const saveSecondaryLocales = (
    saveSecondaryLocales: SaveSecondaryLocales
) =>
    apiFetch(
        `${saveSecondaryLocales.storeId}/configuration/save-secondary-locales`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(saveSecondaryLocales.data),
        }
    );
