import { FormattedMessage } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import {
    Button,
    DeleteFamilyVariantIllustration,
    Helper,
    Modal,
    Table,
} from 'akeneo-design-system';
import { DeletionListType } from '../model/MetafieldAssociationModel';

const RightPanelContainer = styled.div`
    text-align: left;
    max-width: 700px;
`;

const TitleContainer = styled.div`
    line-height: 1.8rem;
`;

const HelperContainer = styled.div`
    margin-top: 25px;
`;

const AssociationDeletionListContainer = styled.div`
    margin-top: 17px;
    overflow-y: auto;
    max-height: 400px;
    -ms-overflow-style: none;
`;

const NUMBER_OF_DEFINITIONS_BY_ASSOCIATION = 4;

type Props = {
    closeModal: () => void;
    associationDeletionList: DeletionListType;
    save: () => void;
};
const AssociationDeselectionModal = ({
    closeModal,
    associationDeletionList,
    save,
}: Props) => {
    let numberOfDefinitionsErased =
        associationDeletionList.length * NUMBER_OF_DEFINITIONS_BY_ASSOCIATION;
    return (
        <Modal
            onClose={closeModal}
            closeTitle={'Cancel'}
            illustration={<DeleteFamilyVariantIllustration />}
        >
            <>
                <RightPanelContainer>
                    <TitleContainer>
                        <Modal.Title>
                            <FormattedMessage
                                id='mxdcDv'
                                defaultMessage='Confirm major changes'
                            />
                        </Modal.Title>
                    </TitleContainer>

                    <HelperContainer>
                        <Helper level='warning'>
                            <FormattedMessage
                                id='XqAkZF'
                                defaultMessage='You deselected some associations already synchronized to Shopify metafields. Please note that the {numberOfDefinitionsErased} definitions and all values of these metafields will be erased during the next synchronization.'
                                values={{
                                    numberOfDefinitionsErased:
                                        numberOfDefinitionsErased,
                                }}
                            />
                        </Helper>
                    </HelperContainer>

                    <AssociationDeletionListContainer>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='QdVIpc'
                                        defaultMessage='PIM association'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='y1Qr81'
                                        defaultMessage='Level'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='W2JBdp'
                                        defaultMessage='Impact'
                                    />
                                </Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {associationDeletionList.map((association) => (
                                    <>
                                        <Table.Row key={association.label}>
                                            <Table.Cell>
                                                {association.label}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {association.reasonBy
                                                    .productLevel && (
                                                    <FormattedMessage
                                                        id='x/ZVlU'
                                                        defaultMessage='Product'
                                                    />
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage
                                                    id='BGJKmm'
                                                    defaultMessage='{reason}'
                                                    values={{
                                                        reason: association
                                                            .reasonBy
                                                            .productLevel,
                                                    }}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row key={association.label}>
                                            <Table.Cell>
                                                {association.label}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {association.reasonBy
                                                    .variantLevel && (
                                                    <FormattedMessage
                                                        id='OK5+Fh'
                                                        defaultMessage='Variant'
                                                    />
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <FormattedMessage
                                                    id='BGJKmm'
                                                    defaultMessage='{reason}'
                                                    values={{
                                                        reason: association
                                                            .reasonBy
                                                            .variantLevel,
                                                    }}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    </>
                                ))}
                            </Table.Body>
                        </Table>
                    </AssociationDeletionListContainer>
                </RightPanelContainer>
            </>

            <Modal.BottomButtons>
                <Button level='tertiary' onClick={closeModal}>
                    <FormattedMessage id='47FYwb' defaultMessage='Cancel' />
                </Button>
                <Button level='primary' onClick={save}>
                    <FormattedMessage
                        id='b5nahW'
                        defaultMessage='Save selection'
                    />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};

export { AssociationDeselectionModal };
