import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { fetchFeatureFlags } from './fetchFeatureFlags';

export const useFeatureFlags = () => {
    const { data, isLoading } = useQuery({
        queryKey: 'fetchFeatureFlags',
        queryFn: fetchFeatureFlags,
        staleTime: Infinity,
    });

    const isFeatureEnabled = useCallback(
        (feature: string) => {
            if (undefined === data) {
                return false;
            }
            if (undefined === data[feature]) {
                console.warn(`Feature flag "${feature}" doesn't exist`);

                return false;
            }

            return data[feature];
        },
        [data]
    );

    return { isFeatureEnabled, isLoading };
};
