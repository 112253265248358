import React from 'react';
import { Loading } from '../../component';
import { useGetConnectorConfig } from './hook/useGetConnectorConfig';

type Props = {
    children: React.ReactNode;
    fallback: (isConfigInvalid: boolean) => React.ReactNode;
};

export const ValidConfigurationGuard = ({ children, fallback }: Props) => {
    const getConnectorConfigQuery = useGetConnectorConfig();
    const isConfigInvalid = !getConnectorConfigQuery.data;

    if (getConnectorConfigQuery.isLoading) {
        return <Loading />;
    }

    if (isConfigInvalid) {
        return <>{fallback(isConfigInvalid)}</>;
    }

    return <>{children}</>;
};
