import { Button } from 'akeneo-design-system';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

type Props = {
    link: string;
};

const ReadMore = ({ link }: Props) => {
    return (
        <Container>
            <Button level='tertiary' ghost href={link} target='_blank'>
                <FormattedMessage id='S++WdB' defaultMessage='Read more' />
            </Button>
        </Container>
    );
};

const Container = styled.div`
    text-align: right;
    margin-top: 5px;
`;

export { ReadMore };
