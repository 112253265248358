import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useToaster } from '../../../util/toast';
import { useSelector } from '../store/StoreProvider';
import {
    useDispatch as useDispatchSecondaryLocales,
    useSelector as useSelectorSecondaryLocales,
} from '../SecondaryLocales/store/StoreProvider';
import { getConnectorConfigQueryKey } from './useGetConnectorConfig';
import { useSaveConnectorConfigForm } from './useSaveConnectorConfigForm';
import { SecondaryLocalesMappingType } from '../SecondaryLocales/model/SecondaryLocalesModel';
import {
    getSecondaryLocales,
    getSecondaryLocalesQueryKey,
} from '../SecondaryLocales/api/getMultiLocales';
import { useCurrentStore } from '../../MultiStore';
import { getSecondaryLocalesMapped } from '../SecondaryLocales/api/getMultiLocalesMapped';
import * as InstabilityType from '../../../util/InstabilityType';

export const useSaveConfiguration = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const queryClient = useQueryClient();
    const { currentStoreId } = useCurrentStore();
    const [forceFetchMapping, setForceFetchMapping] = useState(false);

    useQuery(
        [currentStoreId, getSecondaryLocalesQueryKey],
        () =>
            Promise.all([
                getSecondaryLocales(currentStoreId),
                getSecondaryLocalesMapped(currentStoreId),
            ]),
        {
            enabled: forceFetchMapping,
            onSuccess: ([secondaryLocales, secondaryLocalesMapped]) => {
                if (
                    secondaryLocales.error &&
                    secondaryLocales.error === InstabilityType.pim_instability
                ) {
                    return;
                }
                if (
                    secondaryLocales.error &&
                    secondaryLocales.error ===
                        InstabilityType.shopify_instability
                ) {
                    return;
                }
                dispatch({
                    type: 'secondaryLocales/fetch/collections',
                    activatedShopifyLanguages:
                        secondaryLocales.activatedShopifyLanguages,
                    pimEnabledLocales: secondaryLocales.pimEnabledLocales,
                });

                dispatch({
                    type: 'secondaryLocalesMapped/fetch',
                    secondaryLocalesMapped: secondaryLocalesMapped,
                });
            },
        }
    );

    const state = useSelector((state) => state);
    const stateSecondaryLocales = useSelectorSecondaryLocales((state) => state);
    const dispatch = useDispatchSecondaryLocales();

    const saveConnectorConfigForm = useSaveConnectorConfigForm();

    const [isSaving, setIsSaving] = useState(false);

    const save = async () => {
        setIsSaving(true);

        const success = await saveConnectorConfigForm(
            state.connectorConfigForm
        );
        await queryClient.invalidateQueries(getConnectorConfigQueryKey);

        if (success) {
            addToast(
                intl.formatMessage({
                    id: 'CQl2wT',
                    defaultMessage: 'Configuration successfully updated.',
                }),
                'success'
            );

            if (null !== state.connectorConfigForm.data) {
                dispatch({
                    type: 'mainLocale/update',
                    newMainLocale: state.connectorConfigForm.data.locale,
                });

                checkSecondaryLocales(state.connectorConfigForm.data.locale);

                setForceFetchMapping(true);
            }
        } else {
            addToast(
                intl.formatMessage({
                    id: 'TLle4b',
                    defaultMessage: 'Failed to update Configuration.',
                }),
                'error'
            );
        }

        setIsSaving(false);
    };

    const checkSecondaryLocales = (mainLocale: string | null) => {
        if (null === mainLocale) {
            return;
        }
        Object.values(stateSecondaryLocales.secondaryLocalesMapping).forEach(
            (value: SecondaryLocalesMappingType) => {
                if (value.pimValue === mainLocale) {
                    addToast(
                        intl.formatMessage(
                            {
                                defaultMessage:
                                    '{mainLocale} is now your main locale and has been removed from your secondary locales.',
                                id: 'RJ7Edp',
                            },
                            { mainLocale }
                        ),
                        'warning'
                    );

                    if (null !== value.shopifyKey) {
                        dispatch({
                            type: 'secondaryLocalesMapping/remove',
                            secondaryLocaleToRemove: value.shopifyKey,
                        });
                    }
                }
            }
        );
    };

    return { save, isSaving };
};
