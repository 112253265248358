import { FormattedMessage } from 'react-intl';
import { DeleteStoreConfirmationModal } from './DeleteStoreConfirmationModal';
import { SyntheticEvent, useState } from 'react';
import { Button, Level } from 'akeneo-design-system';

type Props = {
    disabled: boolean;
    level: Level;
    ghost: boolean;
    onConfirm: () => Promise<void>;
};

export const DeleteStoreButton = ({
    disabled = false,
    level = 'tertiary',
    ghost = true,
    onConfirm,
}: Props) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleShowConfirmationModal = (
        event: SyntheticEvent<Element, Event>
    ) => {
        event.stopPropagation();
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    return (
        <>
            <Button
                disabled={disabled}
                level={level}
                ghost={ghost}
                onClick={handleShowConfirmationModal}
            >
                <FormattedMessage id='ALk0CC' defaultMessage='DELETE STORE' />
            </Button>
            {showConfirmation && (
                <DeleteStoreConfirmationModal
                    onConfirm={onConfirm}
                    onCancel={handleCancel}
                />
            )}
        </>
    );
};
