import { Helper } from 'akeneo-design-system';
import {
    GlobalReportModel,
    SynchronizationModel,
} from '../model/SynchronizationReportModel';
import { SynchronizationHistoryStatus } from '../../Synchronization/model/SynchronizationHistoryStatus';

type Props = {
    synchronizationModel: SynchronizationModel | null;
    globalReport: GlobalReportModel | null;
};

export const StatusReport = ({ synchronizationModel, globalReport }: Props) => {
    return (
        <>
            {synchronizationModel!.status ===
                SynchronizationHistoryStatus.Success && (
                <Helper level='success'>
                    {synchronizationModel?.status_message}
                </Helper>
            )}

            {synchronizationModel?.status ===
                SynchronizationHistoryStatus.Warning && (
                <Helper level='warning'>
                    {synchronizationModel?.status_message}
                </Helper>
            )}

            {synchronizationModel?.status ===
                SynchronizationHistoryStatus.Error && (
                <Helper level='error'>
                    {synchronizationModel?.status_message}
                </Helper>
            )}

            {synchronizationModel?.status ===
                SynchronizationHistoryStatus.Stopped && (
                <Helper level='error'>
                    {synchronizationModel?.status_message}
                </Helper>
            )}

            <br />
        </>
    );
};
