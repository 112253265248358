import { NumberInput } from 'akeneo-design-system';

type Props = {
    minCompleteness: number | null;
    onChange: (value: string) => void;
};

const CompletenessFilterInput = ({ onChange, minCompleteness }: Props) => {
    const handleCompletenessChange = (newValue: string) => {
        let value: number = parseInt(newValue);
        if (value < 0) {
            value = 0;
        }
        if (value > 100) {
            value = 100;
        }
        onChange(value.toString());
    };

    return (
        <NumberInput
            onChange={handleCompletenessChange}
            min={0}
            max={100}
            value={minCompleteness ? minCompleteness.toString() : ''}
        />
    );
};

export { CompletenessFilterInput };
