import { Breadcrumb } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getStores } from './api/getStores';
import { useCurrentStore } from './CurrentStoreContext';

export const StoreBreadcrumb = () => {
    const navigate = useNavigate();

    const { currentStoreId } = useCurrentStore();
    const { isSuccess, data: stores } = useQuery({
        queryKey: 'store',
        queryFn: getStores,
        staleTime: 5000,
    });
    if (!isSuccess) {
        return null;
    }

    const currentStoreName = stores.find(
        (store) => store.id === currentStoreId
    )?.name;

    return (
        <Breadcrumb>
            <Breadcrumb.Step onClick={() => navigate('/')}>
                <FormattedMessage id='qb6eps' defaultMessage='Store List' />
            </Breadcrumb.Step>
            <Breadcrumb.Step>{currentStoreName}</Breadcrumb.Step>
        </Breadcrumb>
    );
};
