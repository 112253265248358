import { Immutable, produce } from 'immer';
import { ConnectorConfig } from '../model/ConnectorConfig';
import { ConnectorConfigOptions } from '../model/ConnectorConfigOptions';
import { ConnectorConfigForm } from '../model/ConnectorConfigForm';
import { ConnectorConfigErrors } from '../model/ConnectorConfigErrors';
import { isEqual } from 'lodash';
import { PreExistingCatalogIdentifierValues } from '../model/PreExistingCatalogIdentifierValues';

export type State = Immutable<{
    connectorConfigForm: ConnectorConfigForm;
    connectorConfigOptions: ConnectorConfigOptions | null;
    preExistingCatalogIdentifierValues: PreExistingCatalogIdentifierValues | null;
}>;

export const initialState: State = {
    connectorConfigForm: {
        defaultData: null,
        data: null,
        errors: {},
        isDirty: false,
    },
    connectorConfigOptions: null,
    preExistingCatalogIdentifierValues: null,
};

export type Action =
    | {
          type: 'fetchConfiguration/fulfilled';
          payload: {
              connectorConfig: ConnectorConfig | null;
          };
      }
    | {
          type: 'getConnectorConfigOptions/fulfilled';
          connectorConfigOptions: ConnectorConfigOptions;
      }
    | {
          type: 'getPreExistingCatalogIdentifierValues/fulfilled';
          preExistingCatalogIdentifierValues: PreExistingCatalogIdentifierValues;
      }
    | {
          type: 'connectorConfigForm/changed';
          connectorConfig: ConnectorConfig;
      }
    | {
          type: 'connectorConfigForm/reset';
      }
    | {
          type: 'saveConnectorConfig/fulfilled';
      }
    | {
          type: 'saveConnectorConfig/rejected';
          errors: ConnectorConfigErrors;
      };

export const reducer = produce<(draft: State, action: Action) => State>(
    (draft, action) => {
        switch (action.type) {
            case 'fetchConfiguration/fulfilled':
                if (
                    Object.keys(draft.connectorConfigForm.errors).length === 0
                ) {
                    draft.connectorConfigForm = {
                        defaultData: action.payload.connectorConfig,
                        data: action.payload.connectorConfig,
                        errors: {},
                        isDirty: false,
                    };
                }

                break;

            case 'getConnectorConfigOptions/fulfilled':
                draft.connectorConfigOptions = action.connectorConfigOptions;
                break;

            case 'getPreExistingCatalogIdentifierValues/fulfilled':
                draft.preExistingCatalogIdentifierValues =
                    action.preExistingCatalogIdentifierValues;
                break;

            case 'connectorConfigForm/changed':
                draft.connectorConfigForm.data = action.connectorConfig;
                draft.connectorConfigForm.isDirty = !isEqual(
                    action.connectorConfig,
                    draft.connectorConfigForm.defaultData
                );
                break;

            case 'connectorConfigForm/reset':
                draft.connectorConfigForm = {
                    defaultData: null,
                    data: null,
                    errors: {},
                    isDirty: false,
                };
                break;

            case 'saveConnectorConfig/fulfilled':
                draft.connectorConfigForm = {
                    defaultData: draft.connectorConfigForm.data,
                    data: draft.connectorConfigForm.data,
                    errors: {},
                    isDirty: false,
                };
                break;
            case 'saveConnectorConfig/rejected':
                draft.connectorConfigForm.errors = action.errors;
                break;
        }

        return draft;
    }
);
