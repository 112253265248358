import { AssetCollectionAttributes } from '../model/AssetCollectionAttribute';
import { AssetAttributesPerAssetFamily } from '../model/AssetAttributePerAssetFamily';
import { apiFetch } from '../../../../../../util/apiFetch';

type Result = {
    assetCollectionAttributes: AssetCollectionAttributes[];
    assetAttributesPerAssetFamily: {
        [asset_family_code: string]: AssetAttributesPerAssetFamily[];
    };
};

export const getPimAssetAttributes = (storeId: string, scope: string) =>
    apiFetch<Result>(`${storeId}/mapping/pim-asset-attributes/${scope}`).then(
        (result) => result
    );
