import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexLayout } from '../../component/FlexLayout';
import { ConnectorConfigForm } from './component/ConnectorConfigForm';
import { ConfigurationProvider } from './ConfigurationProvider';
import { SaveConfigurationButton } from './SaveConfigurationButton';
import { DisabledConfiguration } from './component/DisabledConfiguration';
import { RunningSynchronizationGuard } from '../Synchronization';
import { ResetConfigurationButton } from './ResetConfigurationButton';
import { FeatureGuard } from '../../util/featureFlag';
import { SecondaryLocalesProvider } from './SecondaryLocales/SecondaryLocalesProvider';
import { Route, Routes } from 'react-router-dom';
import { SecondaryLocales } from './SecondaryLocales/SecondaryLocales';
import { ResetLinksButton } from './ResetLinksButton';
import { MarketsPricing } from './MarketsPricing/MarketsPricing';
import { MarketsPricingProvider } from './MarketsPricing/MarketsPricingProvider';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 10px 10px;
`;

export const Configuration = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledConfiguration />}>
            <ConfigurationProvider>
                <SecondaryLocalesProvider>
                    <MarketsPricingProvider>
                        <ButtonContainer>
                            <SaveConfigurationButton />
                            <FeatureGuard feature='reset_store'>
                                <ResetConfigurationButton />
                                <ResetLinksButton />
                            </FeatureGuard>
                        </ButtonContainer>
                        <FlexLayout>
                            <FlexColumn>
                                <Routes>
                                    <Route
                                        path='/'
                                        element={<ConnectorConfigForm />}
                                    />
                                    <Route
                                        path='secondary-locales'
                                        element={<SecondaryLocales />}
                                    />
                                    <Route
                                        path='markets-pricing'
                                        element={<MarketsPricing />}
                                    />
                                </Routes>
                            </FlexColumn>
                        </FlexLayout>
                    </MarketsPricingProvider>
                </SecondaryLocalesProvider>
            </ConfigurationProvider>
        </RunningSynchronizationGuard>
    );
};
