import { useState } from 'react';
import { useQuery } from 'react-query';
import { MediaSource } from '../../../model/MediaSource';
import { MediaMapping } from '../model/MediaMapping';
import { useCurrentStore } from '../../../../../MultiStore';
import { getVariantMediaMapping } from '../api/getVariantMediaMapping';

export const fetchMediaMappingQueryKey = 'fetchVariantMediaMapping';

export const useMediaMapping = () => {
    const { currentStoreId } = useCurrentStore();

    const [mapping, setMapping] = useState<MediaMapping>({
        source: MediaSource.Images,
        attributeCode: null,
    });

    useQuery(
        fetchMediaMappingQueryKey,
        () => getVariantMediaMapping(currentStoreId),
        { onSuccess: setMapping }
    );

    return { mapping, setMapping };
};
