import { useIntl } from 'react-intl';
import { useToaster } from '../../../util/toast';
import { useState } from 'react';
import { resetLinks } from '../api/resetLinks';
import { useCurrentStore } from '../../MultiStore';

export const useResetLinks = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const { currentStoreId } = useCurrentStore();

    const [isResetting, setIsResetting] = useState(false);

    const resetAppLinks = async () => {
        setIsResetting(true);

        await resetLinks(currentStoreId);

        addToast(
            intl.formatMessage({
                defaultMessage: 'The links have been successfully reset.',
                id: 'aWg0RV',
            }),
            'success'
        );

        setIsResetting(false);
    };

    return { resetAppLinks, isResetting };
};
