import { Button } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';

type Props = {
    onClick: () => void;
};

export const EditSecondaryLocalesMappingButton = ({ onClick }: Props) => (
    <Button level='secondary' ghost={true} onClick={onClick}>
        <FormattedMessage id='8sCWjC' defaultMessage='EDIT SECONDARY LOCALES' />
    </Button>
);
