import { useCurrentStore } from '../../../../MultiStore';
import { useQuery } from 'react-query';
import { getPimAssetAttributes } from '../../Product/MediaMapping/api/getPimAssetAttributes';
import { SynchronizationScope } from '../../../../Synchronization/model/SynchronizationScope';

type Attributes = Array<{
    code: string;
    label: string;
    assetAttributes: Array<{
        code: string;
        label: string;
        type: string;
        mediaType: string;
    }>;
}>;

export const usePimAssetCollectionAttributes = (): {
    isLoading: boolean;
    attributes: Attributes;
} => {
    const { currentStoreId } = useCurrentStore();

    const { isLoading, data } = useQuery('getPimAssetAttributes', () =>
        getPimAssetAttributes(
            currentStoreId,
            SynchronizationScope.ProductVariant
        )
    );

    if (!data) {
        return { isLoading, attributes: [] };
    }

    const attributes = data.assetCollectionAttributes.map((attribute) => ({
        code: attribute.code,
        label: attribute.label,
        assetAttributes:
            data.assetAttributesPerAssetFamily[attribute.familyCode]!,
    }));

    attributes.sort(({ label: a }, { label: b }) => {
        a = a.toUpperCase();
        b = b.toUpperCase();
        return a < b ? -1 : a > b ? 1 : 0;
    });
    attributes.forEach((attribute) =>
        attribute.assetAttributes.sort(({ label: a }, { label: b }) => {
            a = a.toUpperCase();
            b = b.toUpperCase();
            return a < b ? -1 : a > b ? 1 : 0;
        })
    );

    return { isLoading, attributes };
};
