import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import logoPath from '../../asset/images/app-logo.svg';

const Logo = styled.img`
    height: 56px;
    margin-right: 50px;
`;

const Container = styled.div`
    display: flex;
    padding: 20px 100px;
    max-width: 1440px;
    margin: auto;
`;

type Props = {
    children?: React.ReactNode;
    onClick?: () => void;
};

export const PageHeader = ({ children, onClick }: Props) => {
    const intl = useIntl();

    return (
        <Container>
            <Logo
                src={logoPath}
                alt={intl.formatMessage({
                    defaultMessage: 'Akeneo App for Shopify',
                    id: 'GDMAQe',
                })}
                onClick={onClick}
            />
            {children}
        </Container>
    );
};
