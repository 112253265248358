import { Button, Helper } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useSelector as useVariantSelector } from './ProductVariant/store/StoreProvider';
import { useSaveVariantMapping } from './hook/useSaveVariantMapping';
import { ProductVariantMapping } from './ProductVariant/ProductVariantMapping/ProductVariantMapping';

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
`;

export const ManageVariantMapping = () => {
    const saveVariantMapping = useSaveVariantMapping();

    const { productVariantMapping, productVariantMappingIsDirty } =
        useVariantSelector((state) => state);

    const handleSave = () => {
        if (!productVariantMappingIsDirty) {
            return;
        }
        saveVariantMapping.save(productVariantMapping);
    };

    return (
        <>
            <SaveButtonContainer>
                {productVariantMappingIsDirty && (
                    <Helper inline level='warning'>
                        <FormattedMessage
                            id='2xq0/g'
                            defaultMessage='It seems that some modifications are unsaved'
                        />
                    </Helper>
                )}
                <Button
                    level='primary'
                    onClick={handleSave}
                    disabled={!productVariantMappingIsDirty}
                >
                    <FormattedMessage id='jvo0vs' defaultMessage='Save' />
                </Button>
            </SaveButtonContainer>

            <ProductVariantMapping />
        </>
    );
};
