import { Placeholder, UserGroupsIllustration } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export const Unauthorized = () => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<UserGroupsIllustration />}
            title={intl.formatMessage({
                defaultMessage: 'Your app is not accessible',
                id: '7YXpqS',
            })}
            size='large'
        >
            {intl.formatMessage({
                defaultMessage:
                    'It seems that you are not logged in or your app is not activated! If the problem persists, please contact your Akeneo Customer Success Manager to activate this app.',
                id: 'EebVXm',
            })}
        </Placeholder>
    );
};
