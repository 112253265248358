import { apiFetch } from '../../../util/apiFetch';
import { News, ReleaseNote } from '../model/Announcement';

type Announcement = ReleaseNote | News;
type Result = {
    announcements: Array<Announcement>;
};

export const fetchAnnouncements = (): Promise<Announcement[]> =>
    apiFetch<Result>('announcements').then((result) => result.announcements);
