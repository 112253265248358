import React, { createContext, useContext, useState } from 'react';

type Context = {
    currentPimVersion: string | null;
    setCurrentPimVersion: (storeId: string | null) => void;
};

export const CurrentPimVersionContext = createContext<Context | null>(null);

type Props = {
    children: React.ReactNode;
};

export const CurrentPimVersionProvider = ({ children }: Props) => {
    const [currentPimVersion, setCurrentPimVersion] = useState<string | null>(
        null
    );

    return (
        <CurrentPimVersionContext.Provider
            value={{ currentPimVersion, setCurrentPimVersion }}
        >
            {children}
        </CurrentPimVersionContext.Provider>
    );
};

export const useCurrentPimVersion = () => {
    const context = useContext(CurrentPimVersionContext);
    if (null === context) {
        throw new Error('<CurrentPimVersionProvider /> not setup');
    }

    if (context.currentPimVersion === null) {
        throw new Error('Current pim version is not set');
    }

    return { currentPimVersion: context.currentPimVersion };
};
