import { apiFetch } from '../../../util/apiFetch';

type Result = {
    isShopifyAccessScopesOutdated: boolean;
};

export const getAccessScopes = (currentStoreId: string) =>
    apiFetch<Result>(`${currentStoreId}/retrieve-access-scopes`).then(
        (result) => result
    );
