import styled from 'styled-components';
import { ReactNode } from 'react';

const Container = styled.div`
    max-width: 1500px;
    padding: 0 100px 100px 100px;
    margin: auto;
`;

type Props = {
    children?: ReactNode;
};

export const PageContent = ({ children }: Props) => {
    return <Container>{children}</Container>;
};
