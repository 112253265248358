import { MediaSource } from '../../../model/MediaSource';
import { apiFetch } from '../../../../../../util/apiFetch';
import { MediaMapping } from '../model/MediaMapping';

type Result =
    | {
          source: 'images';
          attributeCode: string | null;
      }
    | {
          source: 'asset_collection';
          attributeCode: string | null;
          assetAttributeCode: string | null;
      };

export const getVariantMediaMapping = async (
    storeId: string
): Promise<MediaMapping> => {
    const mediaMapping = await apiFetch<Result>(
        `${storeId}/mapping/variant-media-mapping`
    );

    if (mediaMapping.source === 'asset_collection') {
        return {
            source: MediaSource.Assets,
            attributeCode: mediaMapping.attributeCode,
            assetAttributeCode: mediaMapping.assetAttributeCode,
        };
    }

    return {
        source: MediaSource.Images,
        attributeCode: mediaMapping.attributeCode,
    };
};
