import { Checkbox } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { selectAttributeMapping } from '../selector/selectAttributeMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';

const CheckboxLabelContainer = styled.div`
    font-size: ${({ theme }) => theme.fontSize.default};
    white-space: normal;
`;

type Props = {
    shopifyAttributeCode: string;
};

export const HasAttributePerFamilyToggle = ({
    shopifyAttributeCode,
}: Props) => {
    const dispatch = useDispatch();

    const attributeMapping = useSelector(
        selectAttributeMapping(shopifyAttributeCode)
    );

    const handleChange = (hasAttributePerFamily: boolean) =>
        dispatch({
            type: 'mapping/HasAttributePerFamilyChanged',
            shopifyAttributeCode: shopifyAttributeCode,
            hasAttributePerFamily,
        });

    return (
        <Checkbox
            checked={attributeMapping.hasAttributePerFamily}
            onChange={handleChange}
        >
            <CheckboxLabelContainer>
                <FormattedMessage
                    id='TEOKsa'
                    defaultMessage='Define a specific attribute for each family'
                />
            </CheckboxLabelContainer>
        </Checkbox>
    );
};
