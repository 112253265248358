import { Helper, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Spacer } from '../../../component';
import { fetchSynchronizationHistories } from '../api/fetchSynchronizationHistories';
import { SynchronizationHistoryItem } from './component/SynchronizationHistoryItem';
import {
    SynchronizationHistoryList,
    SynchronizationHistoryListSkeleton,
} from './component/SynchronizationHistoryList';
import { NoHistoryPlaceholder } from './component/NoHistoryPlaceholder';
import { useCurrentStore } from '../../MultiStore/CurrentStoreContext';
import { SynchronizationHistory } from '../model/SynchronizationHistory';

export const History = () => {
    const { currentStoreId } = useCurrentStore();

    const {
        isLoading,
        data: synchronizationHistories,
        isSuccess,
    } = useQuery('fetchSynchronizationHistories', () =>
        fetchSynchronizationHistories(currentStoreId)
    );

    const showSynchronizationHistoryList = function (
        isLoading: boolean,
        isSuccess: boolean,
        synchronizationHistories: SynchronizationHistory[] | undefined
    ) {
        if (isLoading) {
            return <SynchronizationHistoryListSkeleton />;
        }

        if (
            isSuccess &&
            synchronizationHistories &&
            synchronizationHistories.length > 0
        ) {
            return (
                <SynchronizationHistoryList>
                    {synchronizationHistories.map((synchronizationHistory) => (
                        <SynchronizationHistoryItem
                            key={synchronizationHistory.id}
                            synchronizationHistory={synchronizationHistory}
                        />
                    ))}
                </SynchronizationHistoryList>
            );
        }

        return <NoHistoryPlaceholder />;
    };

    return (
        <>
            <Spacer />

            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage id='djJp6c' defaultMessage='History' />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    defaultMessage="Here, you'll access your completed or interrupted synchronizations and their logs for more details."
                    id='boo249'
                />
            </Helper>

            {showSynchronizationHistoryList(
                isLoading,
                isSuccess,
                synchronizationHistories
            )}
        </>
    );
};
