import { apiFetch } from './apiFetch';

type Result = {
    pimVersion: string | null;
};

export const getPimVersion = () =>
    apiFetch<Result>('retrieve-pim-version').then((result) => {
        return result.pimVersion;
    });
