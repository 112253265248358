import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button, Field, Helper, Link, TextInput } from 'akeneo-design-system';
import { useQuery } from 'react-query';
import { isValidPimKey } from '../hook/useIsValidPim';
import { isValidPim } from '../api/isValidPim';
import { helpCenterUrl, pimAuthenticationUrl } from '../../../util/config';

const FormStyle = {
    marginTop: '40px',
};

const FormFieldContainer = styled.div`
    text-align: left;
`;

const FormButtonContainer = styled.div`
    margin-top: 20px;
`;

export const AssociationForm = () => {
    let [pimURL, setPimUrl] = useState('');
    let [invalidUrl, setInvalidUrl] = useState(false);
    let [pimDisabled, setPimDisabled] = useState(false);

    let { refetch } = useQuery(isValidPimKey, () => isValidPim(pimURL), {
        onSuccess: (result) => {
            setInvalidUrl(!result.isValid);
            setPimDisabled(!result.isPimEnabled);
            if (result.isValid && result.isPimEnabled) {
                window.location.replace(pimAuthenticationUrl(pimURL));
            }
        },
        enabled: false,
    });

    const handleAssociation = () => {
        refetch();
    };

    const handlePimURLChange = (value: string) => {
        setPimUrl(value);
    };

    return (
        <form style={FormStyle}>
            <FormFieldContainer>
                <Field label='Pim URL'>
                    <TextInput
                        onChange={handlePimURLChange}
                        placeholder='https://my-pim-url.com'
                        invalid={invalidUrl}
                    />
                    {invalidUrl && (
                        <Helper level='error'>
                            <FormattedMessage
                                id='pgoAIN'
                                defaultMessage='The following URL does not exist.'
                            />
                            <br />
                            <Link target='_blank' href={helpCenterUrl}>
                                <FormattedMessage
                                    id='9R426o'
                                    defaultMessage='Please check our Help Center for more information.'
                                />
                            </Link>
                        </Helper>
                    )}
                    {!invalidUrl && pimDisabled && (
                        <Helper level='error'>
                            <FormattedMessage
                                id='HUZ0nX'
                                defaultMessage='Your Shopify App is not activated.'
                            />
                            <br />
                            <FormattedMessage
                                id='wamWjO'
                                defaultMessage='Please contact your Akeneo Account Manager to learn more about activating this app.'
                            />
                        </Helper>
                    )}
                </Field>
            </FormFieldContainer>
            <FormButtonContainer>
                <Button level='secondary' onClick={handleAssociation}>
                    <FormattedMessage id='AGe75P' defaultMessage='Associate' />
                </Button>
            </FormButtonContainer>
        </form>
    );
};
