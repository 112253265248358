import React from 'react';
import styled from 'styled-components';
import loaderSrc from '../asset/loader.gif';

const Container = styled.div`
    display: flex;
    justify-content: center;
`;

const Illustration = styled.img`
    max-width: 800px;
`;

export const Loading = () => (
    <Container>
        <Illustration src={loaderSrc} alt='' />
    </Container>
);
