import { Button } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
    align-content: flex-end;
    justify-content: flex-end;
`;

type CloseActionProps = {
    onCancel: () => void;
};

export const CloseActionButtons = ({ onCancel }: CloseActionProps) => {
    return (
        <>
            <ButtonsContainer>
                <Button level='tertiary' onClick={onCancel} ghost>
                    <FormattedMessage
                        id='XAczJT'
                        defaultMessage='Back to report'
                    />
                </Button>
            </ButtonsContainer>
        </>
    );
};
