import { Button, Helper } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ProductMapping } from './Product/ProductMapping/ProductMapping';
import { useSelector } from './Product/store/StoreProvider';
import { useSaveSingleMapping } from './hook/useSaveSingleMapping';

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
`;

export const ManageSingleMapping = () => {
    const { mapping, mappingIsDirty } = useSelector((state) => state);
    const saveSingleMapping = useSaveSingleMapping();

    const handleSave = () => {
        if (!mappingIsDirty) {
            return;
        }
        saveSingleMapping.save(mapping);
    };

    return (
        <>
            <SaveButtonContainer>
                {mappingIsDirty && (
                    <Helper inline level='warning'>
                        <FormattedMessage
                            id='2xq0/g'
                            defaultMessage='It seems that some modifications are unsaved'
                        />
                    </Helper>
                )}
                <Button
                    level='primary'
                    onClick={handleSave}
                    disabled={!mappingIsDirty}
                >
                    <FormattedMessage id='jvo0vs' defaultMessage='Save' />
                </Button>
            </SaveButtonContainer>

            <ProductMapping />
        </>
    );
};
