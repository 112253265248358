import { Button, Link, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LogStatus } from '../model/LogStatus';
import { useNavigate } from 'react-router-dom';
import { helpCenterLogsUrl } from '../../../util/config';

const TableHeaderCell = styled(Table.HeaderCell)`
    width: 15%;
`;

const TableCell = styled(Table.Cell)`
    white-space: normal;
`;

const TableActionCell = styled(Table.ActionCell)`
    & > div {
        opacity: 1 !important;
        justify-content: flex-end;
    }
`;

type Props = {
    logsItems: [
        {
            code: string;
            impacted_items: number;
            message: string;
            label: string;
        }
    ];
    logCategorieStatus: string;
    synchronizationId: string;
};

export const LogsReportTableDetail = ({
    logsItems,
    logCategorieStatus,
    synchronizationId,
}: Props) => {
    const navigate = useNavigate();
    return (
        <>
            <Table>
                <Table.Header>
                    <TableHeaderCell>
                        <FormattedMessage id='753yX5' defaultMessage='Label' />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <FormattedMessage
                            id='SzqxUM'
                            defaultMessage='Impacted items'
                        />
                    </TableHeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='T7Ry38'
                            defaultMessage='Message'
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Header>

                <Table.Body>
                    {logsItems.map((logsItem, index) => {
                        return (
                            <Table.Row key={index}>
                                <TableCell>{logsItem.label}</TableCell>
                                <Table.Cell>
                                    {logsItem.impacted_items}
                                </Table.Cell>
                                <TableCell>
                                    <span>
                                        {logsItem.message}
                                        {(logCategorieStatus ===
                                            LogStatus.Error ||
                                            logCategorieStatus ===
                                                LogStatus.Warning) && (
                                            <>
                                                &nbsp;
                                                <Link
                                                    href={
                                                        helpCenterLogsUrl +
                                                        '#' +
                                                        logsItem.code
                                                    }
                                                    target='_blank'
                                                >
                                                    <FormattedMessage
                                                        defaultMessage='Link to helpcenter'
                                                        id='+UeBpA'
                                                    />
                                                </Link>
                                            </>
                                        )}
                                    </span>
                                </TableCell>
                                <TableActionCell>
                                    <Button
                                        level='secondary'
                                        ghost
                                        onClick={() =>
                                            navigate('detailed-logs', {
                                                state: {
                                                    status: logCategorieStatus,
                                                    logsItem: logsItem,
                                                    synchronizationId:
                                                        synchronizationId,
                                                },
                                            })
                                        }
                                    >
                                        <FormattedMessage
                                            id='LYgmWx'
                                            defaultMessage='See Details'
                                        />
                                    </Button>
                                </TableActionCell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    );
};
