import { Button, Helper } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
    align-content: flex-end;
    justify-content: flex-end;
`;

const HelperContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

type SaveActionProps = {
    onCancel: () => void;
    onSave: () => void;
    disabled: boolean;
};

export const SaveActionButtons = ({
    onCancel,
    onSave,
    disabled,
}: SaveActionProps) => {
    return (
        <>
            <ButtonsContainer>
                <Button level='tertiary' onClick={onCancel}>
                    <FormattedMessage id='0WJNP/' defaultMessage='Return' />
                </Button>
                <Button level='primary' onClick={onSave} disabled={disabled}>
                    <FormattedMessage id='jvo0vs' defaultMessage='Save' />
                </Button>
            </ButtonsContainer>
            {!disabled && (
                <HelperContainer>
                    <Helper level='warning' inline={true}>
                        <FormattedMessage
                            id='eSeYnH'
                            defaultMessage='There are unsaved changes'
                        />
                    </Helper>
                </HelperContainer>
            )}
        </>
    );
};
