import React from 'react';
import { PageContent } from '../../component';
import { PageTitle } from './component/PageTitle';
import { ShopifyAssociationLogo } from './component/ShopifyAssociationLogo';
import { AssociationLeftPanel } from './component/AssociationLeftPanel';
import { PanelDivider } from './component/PanelDivider';
import { AssociationRightPanel } from './component/AssociationRightPanel';
import styled from 'styled-components';

const PanelContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 54px;
`;

export const FromShopify = () => {
    return (
        <>
            <PageContent>
                <PageTitle />
                <ShopifyAssociationLogo />
                <PanelContainer>
                    <AssociationLeftPanel />
                    <PanelDivider />
                    <AssociationRightPanel />
                </PanelContainer>
            </PageContent>
        </>
    );
};
