import styled from 'styled-components';

export const SpacerSmall = styled.div`
    height: 10px;
`;

export const Spacer = styled.div`
    height: 20px;
`;

export const SpacerBig = styled.div`
    height: 40px;
`;
