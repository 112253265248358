import React from 'react';
import illustrationPath from '../../../asset/images/illustration-using-shopify.svg';

import { FormattedMessage } from 'react-intl';
import { IllustrationProps } from 'akeneo-design-system/src/illustrations/IllustrationProps';
import styled from 'styled-components';
import { Button, getColor } from 'akeneo-design-system';
import { shopifyAppStoreUrl } from '../../../util/config';
import { FeatureGuard } from '../../../util/featureFlag';

const Illustration = ({ title, size = 330 }: IllustrationProps) => (
    <img alt={title} width={size} height={size} src={illustrationPath} />
);

const Placeholder = styled.div`
    position: absolute;
    top: calc(50% - 80px);
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`;
const ImageContainer = styled.div`
    text-align: center;
`;

const WelcomeTitle = styled.h1`
    color: ${getColor('brand', 100)};
    transform: translateY(-30px);
    font-weight: 400;
    font-size: 28px;
`;

const Description = styled.div`
    font-size: 15px;
    transform: translateY(-15px);
    max-width: 490px;
    margin: auto;
    padding-bottom: 20px;
    color: ${getColor('grey', 140)};
`;

export const NoStore = () => {
    const handleRedirectShopifyAppStore = () => {
        window.location.replace(shopifyAppStoreUrl);
    };

    return (
        <Placeholder>
            <ImageContainer>
                <Illustration />
            </ImageContainer>
            <WelcomeTitle>
                <FormattedMessage
                    id='nLwErv'
                    defaultMessage='Welcome on the Akeneo App for Shopify'
                />
            </WelcomeTitle>
            <Description>
                Before you start using it, please install the App on your
                Shopify store. <br />
                Just click on 'Connect Shopify' and follow the next steps.
            </Description>
            <FeatureGuard feature='add_store'>
                <Button
                    level='secondary'
                    onClick={handleRedirectShopifyAppStore}
                >
                    <FormattedMessage
                        id='gfzAPR'
                        defaultMessage='Connect Shopify'
                    />
                </Button>
            </FeatureGuard>
        </Placeholder>
    );
};
