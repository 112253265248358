export const pim_catalog_date = 'pim_catalog_date';
export const pim_catalog_identifier = 'pim_catalog_identifier';
export const pim_catalog_simpleselect = 'pim_catalog_simpleselect';
export const pim_catalog_metric = 'pim_catalog_metric';
export const pim_catalog_text = 'pim_catalog_text';
export const pim_catalog_multiselect = 'pim_catalog_multiselect';
export const pim_catalog_number = 'pim_catalog_number';
export const pim_catalog_price_collection = 'pim_catalog_price_collection';
export const pim_catalog_textarea = 'pim_catalog_textarea';
export const pim_catalog_boolean = 'pim_catalog_boolean';
