import React from 'react';
import { Unauthorized } from '.';
import {
    AppNamespaceDeactivated,
    PageContent,
    PageHeader,
} from '../../component';
import { UnauthorizedError } from '../../util/apiFetch';
import { BadRequestError } from '../../util/apiFetch/BadRequestError';
import { ForbiddenError } from '../../util/apiFetch/ForbiddenError';
import { InternalServerError } from '../../util/apiFetch/InternalServerError';
import { BadRequest } from './component/BadRequest';
import { ClientError } from './component/ClientError';
import { ServerError } from './component/ServerError';

type Props = {
    children: React.ReactNode;
};
export class ErrorBoundary extends React.Component<Props, { error?: Error }> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromError(error: Error) {
        console.error(error);

        return { error };
    }

    render() {
        if (this.state.error) {
            return (
                <>
                    <PageHeader></PageHeader>
                    <PageContent>
                        {this.getErrorComponent(this.state.error)}
                    </PageContent>
                </>
            );
        }

        return <>{this.props.children}</>;
    }

    private getErrorComponent(error: Error) {
        if (error instanceof BadRequestError) {
            return <BadRequest />;
        }

        if (error instanceof UnauthorizedError) {
            return <Unauthorized />;
        }

        if (error instanceof ForbiddenError) {
            return <AppNamespaceDeactivated />;
        }

        if (error instanceof InternalServerError) {
            return <ServerError />;
        }

        return <ClientError error={error} />;
    }
}
