import { Helper, Link, Pagination, Search, Table } from 'akeneo-design-system';
import { LogStatus } from '../../model/LogStatus';
import { Log } from '../DetailedModalReport';
import { helpCenterLogsUrl } from '../../../../util/config';
import { FormattedMessage } from 'react-intl';
import { Loading } from '../../../../component';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { getSynchronizationReportLogs } from '../api/getSynchronizationReportLogs';
import { useCurrentStore } from '../../../MultiStore';
import { useMemo, useState } from 'react';
import { ReportLogModel } from '../model/SynchronizationReportLogsModel';
import styled from 'styled-components';
import { DetailedModalReportDropdown } from './DetailedModalReportDropdown';
import { NoLogInSearch } from './NoLogInSearch';

type Props = {
    status: string;
    synchronizationId: string;
    logsItem: Log;
};
type Level = 'info' | 'warning' | 'error' | 'success';

const ITEM_PER_PAGE = 25;

const PaginattionBlock = styled.div`
    height: 44px;
`;

const TableCellMessage = styled(Table.Cell)`
    white-space: normal;
    width: 100%;
`;

const TableCellAction = styled(Table.ActionCell)`
    & > div {
        opacity: 1 !important;
        justify-content: flex-end;
    }
`;

const SearchSpan = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

export const DetailedModalReportContent = ({
    status,
    synchronizationId,
    logsItem,
}: Props) => {
    const intl = useIntl();
    const { currentStoreId } = useCurrentStore();

    let level: Level | undefined;
    switch (status) {
        case LogStatus.Error:
            level = 'error';
            break;
        case LogStatus.Warning:
            level = 'warning';
            break;
        default: // LogStatus.Info:
            level = 'info';
    }

    const [synchronizationReportLogs, setSynchronizationReportLogs] =
        useState<ReportLogModel | null>(null);
    const { isFetching } = useQuery(
        'getSynchronizationReport',
        () =>
            Promise.all([
                getSynchronizationReportLogs(
                    currentStoreId,
                    synchronizationId,
                    logsItem.code
                ),
            ]),
        {
            onSuccess: ([synchronizationReportLogs]) => {
                setSynchronizationReportLogs(synchronizationReportLogs);
            },
        }
    );

    const [currentPage, setCurrentPage] = useState(1);

    let [searchValue, setSearchValue] = useState('');
    const filteredLogs = useMemo(() => {
        setCurrentPage(1);

        if (searchValue === '') {
            return synchronizationReportLogs?.logs;
        }
        return synchronizationReportLogs?.logs?.filter((log) =>
            log.message
                .toString()
                .toUpperCase()
                .includes(searchValue.toUpperCase())
        );
    }, [synchronizationReportLogs, searchValue]);

    const startIndex = (currentPage - 1) * ITEM_PER_PAGE;
    const endIndex = startIndex + ITEM_PER_PAGE;

    const reportLogs = filteredLogs?.slice(startIndex, endIndex);

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    if (isFetching) {
        return <Loading />;
    }

    return (
        <>
            <Helper level={level}>
                {logsItem.message}
                &nbsp;
                <Link
                    href={helpCenterLogsUrl + '#' + logsItem.code}
                    target='_blank'
                >
                    <FormattedMessage
                        defaultMessage='Link to helpcenter'
                        id='+UeBpA'
                    />
                </Link>
            </Helper>

            <br />

            <Search
                onSearchChange={setSearchValue}
                placeholder={intl.formatMessage({
                    id: 'xmcVZ0',
                    defaultMessage: 'Search',
                })}
                searchValue={searchValue}
                title={intl.formatMessage({
                    id: 'a8smtK',
                    defaultMessage: 'Search by log message',
                })}
            >
                <SearchSpan>
                    <FormattedMessage
                        id='xy2439'
                        defaultMessage='{total, plural, =0 {No result} one {# result} other {# results}}'
                        values={{
                            total: filteredLogs?.length,
                        }}
                    />
                </SearchSpan>
            </Search>

            {filteredLogs!.length === 0 ? (
                <NoLogInSearch />
            ) : (
                <>
                    <PaginattionBlock>
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={ITEM_PER_PAGE}
                            totalItems={filteredLogs!.length}
                            followPage={handlePageClick}
                        />
                    </PaginattionBlock>

                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id='T7Ry38'
                                    defaultMessage='Message'
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {reportLogs?.map((log, index) => (
                                <Table.Row>
                                    <TableCellMessage>
                                        {log.message}
                                    </TableCellMessage>
                                    <TableCellAction>
                                        {log.url === null &&
                                        log.shopifyUrl === null ? (
                                            <></>
                                        ) : (
                                            <DetailedModalReportDropdown
                                                url={log.url}
                                                shopifyUrl={log.shopifyUrl}
                                            />
                                        )}
                                    </TableCellAction>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    <Pagination
                        currentPage={currentPage}
                        itemsPerPage={ITEM_PER_PAGE}
                        totalItems={filteredLogs!.length}
                        followPage={handlePageClick}
                    />
                </>
            )}
        </>
    );
};
