import { MediaSource } from '../../../model/MediaSource';
import { MediaMapping } from '../model/MediaMapping';
import { apiFetch } from '../../../../../../util/apiFetch';

export const saveVariantMediaMapping = async ({
    storeId,
    mapping,
}: {
    storeId: string;
    mapping: MediaMapping;
}) => {
    let data = null;

    if (mapping.source === MediaSource.Images) {
        data = {
            source: mapping.source,
            attribute_code: mapping.attributeCode ?? null,
        };
    } else if (mapping.source === MediaSource.Assets) {
        data = {
            source: mapping.source,
            attribute_code: mapping.attributeCode ?? null,
            asset_attribute_code: mapping.attributeCode
                ? mapping.assetAttributeCode
                : null,
        };
    }

    await apiFetch(`${storeId}/mapping/save-variant-media-mapping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mapping: { data },
        }),
    });
};
