import { apiFetch } from '../../../../util/apiFetch';
import { MetafieldAttribute } from '../model/MetafieldAttributeModel';

type Result = {
    metafieldAttributeMapping: MetafieldAttribute[];
};

export const getMetafieldAttributeMapping = (storeId: string) =>
    apiFetch<Result>(
        `${storeId}/metafield/get-metafield-attribute-mapping`
    ).then((result) => result?.metafieldAttributeMapping ?? []);
