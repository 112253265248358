import { Placeholder, ServerErrorIllustration } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export const ServerError = () => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<ServerErrorIllustration />}
            size='large'
            title={intl.formatMessage({
                id: 'RZx/ih',
                defaultMessage: '500 Internal Server Error',
            })}
        />
    );
};
