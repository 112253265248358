import { Button } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

type Props = {
    noMappedMetafields: boolean;
};

export const ManageMetafieldsButton = ({
    noMappedMetafields = true,
}: Props) => {
    const navigate = useNavigate();

    return (
        <Button level='primary' onClick={() => navigate('selection')}>
            {noMappedMetafields ? (
                <FormattedMessage
                    id='AGF3ET'
                    defaultMessage='Select metafields'
                />
            ) : (
                <FormattedMessage
                    id='Gcr/HE'
                    defaultMessage='Manage metafields'
                />
            )}
        </Button>
    );
};
