import { apiFetch } from '../../../util/apiFetch';
import { SynchronizationScope } from '../model/SynchronizationScope';

const getScopeUrl = (
    scope: SynchronizationScope,
    updated: string | null,
    minCompleteness: number | null,
    filterAttribute: string | null,
    medias: boolean,
    metafields: boolean,
    associations: boolean,
    secondaryLocales: boolean,
    marketsPricing: boolean,
    preExistingProductsOnly: boolean,
    currentStoreId: string
) => {
    if (scope === SynchronizationScope.Product) {
        const completeness = minCompleteness ? minCompleteness.toString() : '';
        if (updated) {
            return (
                `${currentStoreId}/synchronization/product/create-product-synchronization` +
                `?updatedDate=${updated}` +
                `&medias=${medias}` +
                `&metafields=${metafields}` +
                `&associations=${associations}` +
                `&minCompleteness=${completeness}` +
                `&filterAttribute=${filterAttribute}` +
                `&secondaryLocales=${secondaryLocales}` +
                `&marketsPricing=${marketsPricing}` +
                `&preExistingProductsOnly=${preExistingProductsOnly}`
            );
        }

        return (
            `${currentStoreId}/synchronization/product/create-product-synchronization` +
            `?medias=${medias}` +
            `&metafields=${metafields}` +
            `&associations=${associations}` +
            `&minCompleteness=${completeness}` +
            `&filterAttribute=${filterAttribute}` +
            `&secondaryLocales=${secondaryLocales}` +
            `&marketsPricing=${marketsPricing}` +
            `&preExistingProductsOnly=${preExistingProductsOnly}`
        );
    }

    if (scope === SynchronizationScope.ProductVariant) {
        if (updated) {
            return (
                `${currentStoreId}/synchronization/product/create-product-variant-synchronization` +
                `?updatedDate=${updated}` +
                `&medias=${medias}` +
                `&metafields=${metafields}` +
                `&associations=${associations}` +
                `&filterAttribute=${filterAttribute}` +
                `&secondaryLocales=${secondaryLocales}` +
                `&marketsPricing=${marketsPricing}` +
                `&preExistingProductsOnly=${preExistingProductsOnly}`
            );
        }

        return (
            `${currentStoreId}/synchronization/product/create-product-variant-synchronization` +
            `?medias=${medias}` +
            `&metafields=${metafields}` +
            `&associations=${associations}` +
            `&filterAttribute=${filterAttribute}` +
            `&secondaryLocales=${secondaryLocales}` +
            `&marketsPricing=${marketsPricing}` +
            `&preExistingProductsOnly=${preExistingProductsOnly}`
        );
    }

    throw new Error();
};

/**
 * @param scope The scope of the synchronization (PRODUCT or PRODUCT_VARIANT)
 * @param updated Date and time ISO8601 format set to UTC timezone
 * @param minCompleteness The minimum completeness on which the synchronization will retrieve products
 * @param filterAttribute Whether or not the synchronization is filtered on a boolean attribute
 * @param medias Whether or not to import medias
 * @param metafields Whether or not to import metafields
 * @param associations Whether or not to import metafields associations
 * @param secondaryLocales Whether or not to import secondary locales
 * @param preExistingProductsOnly Whether or not to import pre-existing products only
 * @param marketsPricing Whether or not to import markets pricing
 * @param currentStoreId The id of the store on which the synchronization will be launched
 */
export const launchSynchronization = ({
    scope,
    updated,
    minCompleteness,
    filterAttribute,
    medias,
    metafields,
    associations,
    secondaryLocales,
    marketsPricing,
    preExistingProductsOnly,
    currentStoreId,
}: {
    scope: SynchronizationScope;
    updated: string | null;
    minCompleteness: number | null;
    filterAttribute: string | null;
    medias: boolean;
    metafields: boolean;
    associations: boolean;
    secondaryLocales: boolean;
    marketsPricing: boolean;
    preExistingProductsOnly: boolean;
    currentStoreId: string;
}) => {
    return apiFetch(
        getScopeUrl(
            scope,
            updated,
            minCompleteness,
            filterAttribute,
            medias,
            metafields,
            associations,
            secondaryLocales,
            marketsPricing,
            preExistingProductsOnly,
            currentStoreId
        ),
        {
            method: 'POST',
        }
    );
};
