import { useIntl } from 'react-intl';
import { CustomModal } from '../../../component/Modal/CustomModal';
import { SaveActionButtons } from '../../../component/Modal/SaveActionButtons';
import { useNavigate } from 'react-router-dom';
import { SelectSecondaryLocales } from './component/SelectSecondaryLocales';
import { useDispatch, useSelector } from './store/StoreProvider';
import { useMutation } from 'react-query';
import { useCurrentStore } from '../../MultiStore';
import { saveSecondaryLocales } from './api/saveSecondaryLocales';
import { useToaster } from '../../../util/toast';

export const SecondaryLocales = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const addToast = useToaster();
    const dispatch = useDispatch();
    const { currentStoreId } = useCurrentStore();
    const navigateToPreviousPage = async () => {
        navigate('..');
    };

    const saveSecondaryLocalesMutation = useMutation(saveSecondaryLocales);
    const { secondaryLocalesMapping, mappingIsDirty } = useSelector(
        (state) => state
    );

    const handleSave = async () => {
        let locales: { [key: string]: string } = {};

        secondaryLocalesMapping.forEach((secondaryLocales) => {
            if (secondaryLocales.shopifyKey && secondaryLocales.pimValue) {
                locales[secondaryLocales.shopifyKey] =
                    secondaryLocales.pimValue;
            }
        });

        await saveSecondaryLocalesMutation.mutateAsync({
            storeId: currentStoreId,
            data: locales,
        });

        dispatch({
            type: 'secondaryLocales/saved',
            mappingIsDirty: false,
        });
        addToast(
            intl.formatMessage({
                id: 'djDqnI',
                defaultMessage: 'Secondary locales successfully updated.',
            }),
            'success'
        );
        navigateToPreviousPage();
    };

    const handleCancel = () => {
        navigateToPreviousPage();
    };

    return (
        <CustomModal
            title={intl.formatMessage({
                id: 'AC7ids',
                defaultMessage: 'Store Configuration',
            })}
            subTitle={intl.formatMessage({
                id: '8unGTA',
                defaultMessage: 'Edit secondary locales',
            })}
            actions={
                <SaveActionButtons
                    onSave={handleSave}
                    onCancel={handleCancel}
                    disabled={!mappingIsDirty}
                />
            }
        >
            <SelectSecondaryLocales />
        </CustomModal>
    );
};
