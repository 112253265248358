import { useContext } from 'react';
import { ToastContext } from './ToastContext';

export const useToaster = () => {
    const addToast = useContext(ToastContext);
    if (null === addToast) {
        throw new Error();
    }

    return addToast;
};
