import { BadRequestError } from './BadRequestError';
import { ForbiddenError } from './ForbiddenError';
import { UnauthorizedError } from './UnauthorizedError';

export const apiFetch = async <T, E extends Error = Error>(
    input: string,
    init?: RequestInit
): Promise<T> => {
    const parseCookie = (cookie: string) =>
        cookie
            .split(';')
            .map((value: string) => value.split('='))
            .reduce((key: { [index: string]: string }, value: string[]) => {
                key[
                    decodeURIComponent(
                        undefined !== value[0] ? value[0].trim() : ''
                    )
                ] = decodeURIComponent(
                    undefined !== value[1] ? value[1].trim() : ''
                );

                return key;
            }, {});

    const response = await fetch(
        `${process.env.REACT_APP_API_WEB_PATH}/${input}`,
        {
            ...init,
            headers: {
                ...init?.headers,
                'X-Requested-With': 'XMLHttpRequest',
            },
        }
    );

    if (!response.ok) {
        switch (response.status) {
            case 400:
            case 422:
                throw new BadRequestError<E>(await response.json());
            case 401:
                /**
                 * Workaround to prevent displaying the "You are not log in" message to the end user when he tries to
                 * access the app from the PIM but the namespace is disabled or cannot be found in Firestore.
                 * The feature-flags HTTP call occurs too early and that makes authentication process fail, as it would
                 * need too much FE rework to do it the right way, we preferred to make a workaround here.
                 */
                if (input.includes('feature-flags')) {
                    if (
                        !(
                            'is_authenticated_to_pim' in
                            parseCookie(document.cookie)
                        )
                    ) {
                        throw new UnauthorizedError();
                    } else {
                        throw new ForbiddenError();
                    }
                }

                throw new UnauthorizedError();
            case 403:
                throw new ForbiddenError();
            case 503:
                const jsonResponse = await response.json();
                if (!jsonResponse) {
                    throw new Error(
                        `${response.status} ${response.statusText}`
                    );
                }
                return jsonResponse;
            default:
                throw new Error(`${response.status} ${response.statusText}`);
        }
    }

    return await response.json().then((result) => result.data);
};
