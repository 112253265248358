import React from 'react';
import styled from 'styled-components';
import { getColor, MegaphoneIcon, useBooleanState } from 'akeneo-design-system';
import { AnnouncementTypes } from './model/Announcement';
import { ReleaseNotesItem } from './component/ReleaseNotesItem';
import { NewsItem } from './component/NewsItem';
import { SidePanel } from './component/SidePanel';
import { useQuery } from 'react-query';
import { fetchAnnouncements } from './api/fetchAnnouncements';

const Announcements = () => {
    const [
        isAnnouncementsPanelOpen,
        openAnnouncementsPanel,
        closeAnnouncementsPanel,
    ] = useBooleanState(false);
    const query = useQuery({
        queryKey: 'fetchAnnouncements',
        queryFn: fetchAnnouncements,
        staleTime: Infinity,
    });

    return (
        <Container>
            <NotificationIconContainer onClick={openAnnouncementsPanel}>
                <NotificationIconThemed size={20} />
            </NotificationIconContainer>
            {isAnnouncementsPanelOpen && (
                <SidePanel onCloseAnnouncements={closeAnnouncementsPanel}>
                    {query.isSuccess &&
                        query.data.map((announcementItem) => {
                            const key = `${announcementItem.type}-${announcementItem.date}`;
                            return announcementItem.type ===
                                AnnouncementTypes.ReleaseNote ? (
                                <ReleaseNotesItem
                                    data={announcementItem}
                                    key={key}
                                />
                            ) : (
                                <NewsItem data={announcementItem} key={key} />
                            );
                        })}
                </SidePanel>
            )}
        </Container>
    );
};

const Container = styled.div`
    margin: 0 20px 0 auto;
    align-self: center;
`;

const NotificationIconContainer = styled.div`
    width: 32px;
    height: 32px;
    border: 1px #d9dde2 solid; //Color not in DSM
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const NotificationIconThemed = styled(MegaphoneIcon)`
    color: ${getColor('grey', 100)};
`;

export { Announcements };
