import { GraphIllustration, Placeholder } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export const NoHistoryPlaceholder = () => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<GraphIllustration />}
            size='large'
            title={intl.formatMessage({
                id: '9lYLX4',
                defaultMessage: 'There is no logs for the moment',
            })}
        ></Placeholder>
    );
};
