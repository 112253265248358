import React from 'react';
import { useFeatureFlags } from './useFeatureFlag';

type Props = {
    feature: string;
    children: React.ReactNode;
    fallback?: React.ReactNode;
};

export const FeatureGuard = ({ feature, children, fallback }: Props) => {
    const { isFeatureEnabled, isLoading } = useFeatureFlags();

    if (isLoading) {
        return null;
    }

    if (isFeatureEnabled(feature)) {
        return <>{children}</>;
    }

    return <>{fallback}</>;
};
