import { ShopifyAttribute } from '../../model/ShopifyAttribute';
import { State } from '../store/reducer';

export const selectAllowedPimAttributes = (
    shopifyAttribute: ShopifyAttribute,
    pimFamilyCode?: string
) => {
    return (state: State) => {
        let pimAttributes = Object.values(state.pimAttributes).filter(
            ({ type }) => shopifyAttribute.pimAllowedTypes.includes(type)
        );
        if (pimFamilyCode !== undefined) {
            return Object.values(pimAttributes).filter(({ families }) =>
                families.includes(pimFamilyCode)
            );
        }

        return pimAttributes;
    };
};
