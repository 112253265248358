import { Button } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';

type Props = {
    onClick: () => void;
};

export const EditMediaMappingButton = ({ onClick }: Props) => (
    <Button level='secondary' ghost={true} onClick={onClick}>
        <FormattedMessage id='yFA6G3' defaultMessage='EDIT MEDIA MAPPING' />
    </Button>
);
