import { Badge, Button, getColor, Table } from 'akeneo-design-system';
import React from 'react';
import { DateTimeLabel } from '../../../component';
import { SynchronizationHistoryStatusBadge } from '../../Synchronization/History/component/SynchronizationHistoryStatusBadge';
import styled from 'styled-components';
import { Store } from '../api/getStores';
import { useNavigate } from 'react-router-dom';
import { DeleteStoreButton } from './DeleteStoreButton';
import { FormattedMessage } from 'react-intl';
import { shopifyAuthenticationUrl } from '../../../util/config';
import { FeatureGuard } from '../../../util/featureFlag';
import { SynchronizationStatusBadge } from '../../Synchronization/Execution/component/SynchronizationStatusBadge';
import { SynchronizationStatus } from '../../Synchronization/model/SynchronizationStatus';
import { getAccessScopes } from '../../AccessScope/api/getAccessScopes';

const ClickableTableRow = styled(Table.Row)`
    cursor: pointer;
    &:hover > td {
        background-color: ${getColor('grey', 20)};
    }
`;
const DisabledTableRow = styled(Table.Row)`
    cursor: not-allowed;
    &:hover > td {
        background-color: ${getColor('grey', 20)};
    }
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const ReconnectContainer = styled.div`
    padding-right: 30px;
`;

const StoreTitleContainer = styled.div`
    color: ${getColor('brand', 100)};
`;

type Props = {
    store: Store;
    handleStoreDeletion: (storeId: string) => Promise<void>;
    hasSynchronizationInProgress: boolean;
};

export const StoreLine = ({
    store,
    handleStoreDeletion,
    hasSynchronizationInProgress,
}: Props) => {
    const navigate = useNavigate();
    const handleStoreSelection = async (storeId: string) => {
        const accessScopes = await getAccessScopes(storeId);
        if (accessScopes && accessScopes?.isShopifyAccessScopesOutdated) {
            navigate(`${storeId}/outdated`, { state: { shop: store.shop } });
        } else {
            navigate(`/stores/${storeId}/configuration`);
        }
    };

    const handleReconnect = (shop: string) => {
        window.location.replace(shopifyAuthenticationUrl(shop));
    };

    const innerLine = (store: Store) => {
        return (
            <>
                <Table.Cell>
                    <StoreTitleContainer>{store.name}</StoreTitleContainer>
                </Table.Cell>
                <Table.Cell>
                    <Badge
                        level={
                            store.status === 'CONNECTED' ? 'primary' : 'warning'
                        }
                    >
                        {store.status}
                    </Badge>
                </Table.Cell>
                <Table.Cell>
                    {store.lastSynchronizationImport &&
                        store.lastSynchronizationStatus && (
                            <>
                                <DateTimeLabel
                                    dateTime={store.lastSynchronizationImport}
                                />
                            </>
                        )}
                </Table.Cell>
                <Table.Cell>
                    {hasSynchronizationInProgress && (
                        <SynchronizationStatusBadge
                            status={SynchronizationStatus.InProgress}
                        />
                    )}
                    {!hasSynchronizationInProgress &&
                        store.lastSynchronizationStatus && (
                            <SynchronizationHistoryStatusBadge
                                status={store.lastSynchronizationStatus}
                            />
                        )}
                </Table.Cell>
                <Table.Cell>
                    <ActionContainer>
                        {store.status !== 'CONNECTED' && (
                            <ReconnectContainer>
                                <Button
                                    level='primary'
                                    ghost={true}
                                    onClick={() => handleReconnect(store.shop)}
                                >
                                    <FormattedMessage
                                        id='Eg11ZY'
                                        defaultMessage='Reconnect'
                                    />
                                </Button>
                            </ReconnectContainer>
                        )}
                        <FeatureGuard feature='remove_store'>
                            <DeleteStoreButton
                                disabled={hasSynchronizationInProgress}
                                level={'tertiary'}
                                ghost={true}
                                onConfirm={() => handleStoreDeletion(store.id)}
                            />
                        </FeatureGuard>
                    </ActionContainer>
                </Table.Cell>
            </>
        );
    };

    return (
        <>
            {store.status === 'CONNECTED' ? (
                <ClickableTableRow
                    key={`active${store.id}`}
                    onClick={() => handleStoreSelection(store.id)}
                >
                    {innerLine(store)}
                </ClickableTableRow>
            ) : (
                <DisabledTableRow key={`disabled${store.id}`}>
                    {innerLine(store)}
                </DisabledTableRow>
            )}
        </>
    );
};
