import {
    CloseIcon,
    IconButton,
    SelectInput,
    Table,
} from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const AttributeSelectContainer = styled.div`
    width: 300px;
`;

const RemoveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

type Props = {
    attributes: Array<{
        code: string;
        label: string;
        assetAttributes: Array<{
            code: string;
            label: string;
            type: string;
            mediaType: string;
        }>;
    }>;
    value:
        | { attributeCode: null }
        | { attributeCode: string; assetAttributeCode: null | string };
    onChange: (
        value:
            | { attributeCode: null }
            | { attributeCode: string; assetAttributeCode: null | string }
    ) => void;
};

export const AssetAttributeItem = ({ attributes, value, onChange }: Props) => {
    const intl = useIntl();

    const handleAttributeChange = (attributeCode: string | null) => {
        if (attributeCode === null) {
            onChange({ attributeCode: null });
        } else {
            onChange({ attributeCode, assetAttributeCode: null });
        }
    };

    const handleAssetAttributeChange = (
        attributeCode: string,
        assetAttributeCode: string | null
    ) => {
        onChange({ attributeCode, assetAttributeCode });
    };

    const handleRemoveAttribute = () => {
        onChange({ attributeCode: null });
    };

    const getAssetAttributes = (attributeCode: string) =>
        attributes.find((attribute) => attributeCode === attribute.code)
            ?.assetAttributes || [];

    const getAssetAttribute = (
        attributeCode: string,
        assetAttributeCode: string
    ) =>
        getAssetAttributes(attributeCode).find(
            (assetAttribute) => assetAttribute.code === assetAttributeCode
        ) || null;

    return (
        <>
            <Table.Cell>
                <AttributeSelectContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'lvAh1u',
                            defaultMessage:
                                'No asset collection attribute found',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'WB41Gj',
                            defaultMessage: 'Select an asset collection',
                        })}
                        value={value.attributeCode}
                        openLabel={''}
                        onChange={handleAttributeChange}
                        clearable={false}
                    >
                        {attributes.map((attribute) => (
                            <SelectInput.Option
                                key={attribute.code}
                                value={attribute.code}
                            >
                                {attribute.label}
                            </SelectInput.Option>
                        ))}
                    </SelectInput>
                </AttributeSelectContainer>
            </Table.Cell>
            <Table.Cell>
                {value.attributeCode !== null && (
                    <AttributeSelectContainer>
                        <SelectInput
                            emptyResultLabel={intl.formatMessage({
                                id: '7d0gSj',
                                defaultMessage: 'No asset attribute found',
                            })}
                            placeholder={intl.formatMessage({
                                id: '4CLSQB',
                                defaultMessage: 'Select an asset attribute',
                            })}
                            value={value.assetAttributeCode}
                            openLabel={''}
                            onChange={(assetAttributeCode) =>
                                handleAssetAttributeChange(
                                    value.attributeCode,
                                    assetAttributeCode
                                )
                            }
                            clearable={false}
                        >
                            {getAssetAttributes(value.attributeCode).map(
                                (assetAttribute) => (
                                    <SelectInput.Option
                                        key={assetAttribute.code}
                                        value={assetAttribute.code}
                                    >
                                        {assetAttribute.label}
                                    </SelectInput.Option>
                                )
                            )}
                        </SelectInput>
                    </AttributeSelectContainer>
                )}
            </Table.Cell>
            <Table.Cell>
                {value.attributeCode !== null &&
                    value.assetAttributeCode !== null &&
                    getAssetAttribute(
                        value.attributeCode,
                        value.assetAttributeCode
                    )?.type}
            </Table.Cell>
            <Table.Cell>
                {value.attributeCode !== null &&
                    value.assetAttributeCode !== null &&
                    getAssetAttribute(
                        value.attributeCode,
                        value.assetAttributeCode
                    )?.mediaType}
            </Table.Cell>
            <Table.Cell>
                <RemoveButtonContainer>
                    <IconButton
                        icon={<CloseIcon />}
                        title={intl.formatMessage({
                            id: 'S53Eji',
                            defaultMessage: 'Remove attribute',
                        })}
                        onClick={handleRemoveAttribute}
                        disabled={!value.attributeCode}
                        ghost='borderless'
                        level='tertiary'
                    />
                </RemoveButtonContainer>
            </Table.Cell>
        </>
    );
};
