import { ApiIllustration, Placeholder } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export const AppNamespaceDeactivated = () => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<ApiIllustration />}
            size='large'
            title={intl.formatMessage({
                id: 'ZybD0e',
                defaultMessage: 'This app is not yet activated',
            })}
        >
            {intl.formatMessage({
                id: '5rQtAb',
                defaultMessage:
                    'Please contact your Akeneo Customer Success Manager for more information on how to activate this app.',
            })}
        </Placeholder>
    );
};
