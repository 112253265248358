import { CustomModal } from '../../../component/Modal/CustomModal';
import { useIntl } from 'react-intl';
import { SaveActionButtons } from '../../../component/Modal/SaveActionButtons';
import { useNavigate } from 'react-router-dom';
import { MarketsPricingMapping } from './component/MarketsPricingMapping';
import { useDispatch, useSelector } from './store/StoreProvider';
import { useMutation } from 'react-query';
import {
    saveMarketsPricingMapping,
    SaveMarketsPricingMappingErrorResponse,
} from './api/saveMarketsPricingMapping';
import { useCurrentStore } from '../../MultiStore';
import { useToaster } from '../../../util/toast';
import { MarketsPricingType } from './model/MarketsPricingModel';

export const MarketsPricing = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { currentStoreId } = useCurrentStore();
    const dispatch = useDispatch();
    const addToast = useToaster();

    const navigateToPreviousPage = async () => {
        navigate('..');
    };

    const { marketsPricingMapping, mappingIsDirty } = useSelector(
        (state) => state
    );

    const saveMarketsPricingMappingMutation = useMutation(
        saveMarketsPricingMapping
    );

    const handleSave = async () => {
        let newMarketsMappingToSave: MarketsPricingType[] = [];

        marketsPricingMapping.forEach((marketPricing) =>
            newMarketsMappingToSave.push({
                marketId: marketPricing.marketId,
                marketName: marketPricing.marketName,
                locale: marketPricing.locale,
            })
        );

        await saveMarketsPricingMappingMutation.mutateAsync(
            {
                storeId: currentStoreId,
                data: newMarketsMappingToSave,
            },
            {
                onSuccess: (
                    data: SaveMarketsPricingMappingErrorResponse | void
                ) => {
                    if (data?.errorMessage || data?.error?.violations) {
                        dispatch({
                            type: 'marketsPricingMapping/reject',
                            errorMessage:
                                data.errorMessage ??
                                intl.formatMessage({
                                    defaultMessage:
                                        'Some elements in your mapping are no longer available. Please update or remove them.',
                                    id: '2yX+Ae',
                                }),
                        });
                        addToast(
                            intl.formatMessage({
                                id: '/x2Jwb',
                                defaultMessage:
                                    'Markets pricing was not updated.',
                            }),
                            'error'
                        );

                        if (data.error?.violations) {
                            Object.values(data.error.violations).forEach(
                                (violation, row) => {
                                    dispatch({
                                        type: 'marketsPricingMapping/mapping/update',
                                        index: row,
                                        locale: marketsPricingMapping[row]!
                                            .locale,
                                        marketId:
                                            marketsPricingMapping[row]!
                                                .marketId,
                                        marketName:
                                            marketsPricingMapping[row]!
                                                .marketName,
                                        currency:
                                            marketsPricingMapping[row]!
                                                .currency,
                                        errors: violation,
                                    });
                                }
                            );
                        }
                    } else {
                        dispatch({
                            type: 'marketsPricingMapping/saved',
                        });

                        addToast(
                            intl.formatMessage({
                                id: '9RFCnK',
                                defaultMessage:
                                    'Markets pricing successfully updated.',
                            }),
                            'success'
                        );
                        navigateToPreviousPage();
                    }
                },
            }
        );
    };
    const handleCancel = () => {
        navigateToPreviousPage();
    };

    return (
        <CustomModal
            title={intl.formatMessage({
                id: 'AC7ids',
                defaultMessage: 'Store Configuration',
            })}
            subTitle={intl.formatMessage({
                id: '2Ys5Mk',
                defaultMessage: 'Edit markets pricing',
            })}
            actions={
                <SaveActionButtons
                    onSave={handleSave}
                    onCancel={handleCancel}
                    disabled={!mappingIsDirty}
                />
            }
            closeIcon={true}
        >
            <MarketsPricingMapping />
        </CustomModal>
    );
};
