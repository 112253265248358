import { Helper, SectionTitle } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from '../store/StoreProvider';
import { MappingList } from '../../Product/ProductMapping/component/MappingList';
import { MappingSection } from '../../Product/ProductMapping/component/MappingSection';
import { MappingItem } from './component/MappingItem';
import { HasAttributePerFamilyToggle } from './HasAttributePerFamilyToggle';
import { PimAttributeCollectionSelector } from './PimAttributeCollectionSelector';
import { PimAttributeSelector } from './PimAttributeSelector';
import { EditMediaMappingButton } from '../../Component/MediaMapping/EditMediaMappingButton';
import { useNavigate } from 'react-router-dom';

export const ProductVariantMapping = () => {
    const navigate = useNavigate();
    const shopifyAttributes = useSelector((state) => state.shopifyAttributes);
    const shopifyProductModelAttributeSections = useSelector(
        (state) => state.shopifyAttributeSections['PRODUCT_MODEL']
    );
    const shopifyProductVariantAttributeSections = useSelector(
        (state) => state.shopifyAttributeSections['PRODUCT_VARIANT']
    );
    const { pimAttributeErrors } = useSelector((state) => state);

    const endSectionStyle = {
        paddingTop: '40px',
        display: 'block',
    };

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='OLlHHe'
                        defaultMessage='Product Model fields'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='7M7fcH'
                    defaultMessage='In this section you will define the mapping for the product models.'
                />
            </Helper>

            <MappingList>
                {shopifyProductModelAttributeSections &&
                    Object.entries(shopifyProductModelAttributeSections).map(
                        ([key, values]) => (
                            <React.Fragment key={key}>
                                <MappingSection sectionLabel={key} />
                                {Object.values(values).map((value) => (
                                    <MappingItem
                                        key={
                                            value +
                                            shopifyAttributes[value]?.code
                                        }
                                        shopifyAttribute={
                                            shopifyAttributes[value]!
                                        }
                                    >
                                        {shopifyAttributes[value]?.code ===
                                            'IMAGES' && (
                                            <div>
                                                <EditMediaMappingButton
                                                    onClick={() =>
                                                        navigate(
                                                            'media-mapping'
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                        {!shopifyAttributes[value]
                                            ?.collection &&
                                            shopifyAttributes[value]?.code !==
                                                'IMAGES' && (
                                                <PimAttributeSelector
                                                    shopifyAttribute={
                                                        shopifyAttributes[
                                                            value
                                                        ]!
                                                    }
                                                    pimAttributeErrors={
                                                        pimAttributeErrors
                                                    }
                                                />
                                            )}
                                        {shopifyAttributes[value]?.collection &&
                                            shopifyAttributes[value]?.code !==
                                                'IMAGES' && (
                                                <PimAttributeCollectionSelector
                                                    shopifyAttribute={
                                                        shopifyAttributes[
                                                            value
                                                        ]!
                                                    }
                                                />
                                            )}
                                        {shopifyAttributes[value]?.code !==
                                            'IMAGES' && (
                                            <HasAttributePerFamilyToggle
                                                shopifyAttributeCode={
                                                    shopifyAttributes[value]
                                                        ?.code!
                                                }
                                            />
                                        )}
                                    </MappingItem>
                                ))}
                                <tr style={endSectionStyle} />
                            </React.Fragment>
                        )
                    )}
            </MappingList>

            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='Oral7N'
                        defaultMessage='Product variant fields'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='I8bXeu'
                    defaultMessage='In this section you will define the mapping for the product variants. As soon as you define a product variant value as a source from Akeneo PIM, it will always be recorded over a product model value.'
                />
            </Helper>

            <MappingList>
                {shopifyProductVariantAttributeSections &&
                    Object.entries(shopifyProductVariantAttributeSections).map(
                        ([key, values]) => (
                            <React.Fragment key={key}>
                                <MappingSection sectionLabel={key} />
                                {Object.values(values).map((value) => (
                                    <MappingItem
                                        key={
                                            value +
                                            shopifyAttributes[value]?.code
                                        }
                                        shopifyAttribute={
                                            shopifyAttributes[value]!
                                        }
                                    >
                                        {shopifyAttributes[value]?.code ===
                                            'VARIANT_IMAGE' && (
                                            <div>
                                                <EditMediaMappingButton
                                                    onClick={() =>
                                                        navigate(
                                                            'variant-media-mapping'
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                        {shopifyAttributes[value]?.code !==
                                            'VARIANT_IMAGE' &&
                                            !shopifyAttributes[value]
                                                ?.collection && (
                                                <PimAttributeSelector
                                                    shopifyAttribute={
                                                        shopifyAttributes[
                                                            value
                                                        ]!
                                                    }
                                                    pimAttributeErrors={
                                                        pimAttributeErrors
                                                    }
                                                />
                                            )}
                                        {shopifyAttributes[value]?.code !==
                                            'VARIANT_IMAGE' &&
                                            shopifyAttributes[value]
                                                ?.collection && (
                                                <PimAttributeCollectionSelector
                                                    shopifyAttribute={
                                                        shopifyAttributes[
                                                            value
                                                        ]!
                                                    }
                                                />
                                            )}
                                        {shopifyAttributes[value]?.code !==
                                            'VARIANT_IMAGE' && (
                                            <HasAttributePerFamilyToggle
                                                shopifyAttributeCode={
                                                    shopifyAttributes[value]
                                                        ?.code!
                                                }
                                            />
                                        )}
                                    </MappingItem>
                                ))}
                                <tr style={endSectionStyle} />
                            </React.Fragment>
                        )
                    )}
            </MappingList>
        </>
    );
};
