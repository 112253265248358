import { apiFetch } from '../../../../util/apiFetch';
import { Immutable } from 'immer';
import { MarketsPricingMapping } from '../model/MarketsPricingModel';

type SaveMarketsPricingMapping = {
    storeId: string;
    data: Immutable<MarketsPricingMapping>;
};
export type SaveMarketsPricingMappingErrorResponse = {
    errorMessage?: string;
    error?: {
        code: number;
        violations: {
            [mappingRow: string]: { [fieldKey: string]: string[] };
        };
    };
};

export const saveMarketsPricingMapping = (
    saveMarketsPricingMapping: SaveMarketsPricingMapping
) =>
    apiFetch<SaveMarketsPricingMappingErrorResponse | void>(
        `${saveMarketsPricingMapping.storeId}/configuration/save-markets-pricing-mapping`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(saveMarketsPricingMapping.data),
        }
    )
        .then((result) => result)
        .catch((error) => {
            return {
                errorMessage:
                    error.data.error.message ??
                    'Some elements in your mapping are no longer available. Please update or remove them.',
            };
        });
