import { RunningSynchronizationGuard } from '../../Synchronization';
import { DisabledMapping } from '../../NativeFields/component/DisabledMapping';
import { ValidConfigurationGuard } from '../../Configuration';
import { InvalidConfiguration } from '../../NativeFields/component/InvalidConfiguration';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { AssociationsSelected } from './component/AssociationsSelected';
import { AssociationsSelection } from './component/AssociationsSelection';

export const MetafieldAssociations = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledMapping />}>
            <ValidConfigurationGuard
                fallback={(isConfigInvalid) => (
                    <InvalidConfiguration isConfigInvalid={isConfigInvalid} />
                )}
            >
                <Routes>
                    <Route
                        path='selection'
                        element={<AssociationsSelection />}
                    />
                    <Route path='*' element={<AssociationsSelected />} />
                </Routes>
            </ValidConfigurationGuard>
        </RunningSynchronizationGuard>
    );
};
