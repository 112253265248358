import { apiFetch } from '../../../util/apiFetch';

type Result = {
    categoryCode: string;
    locale: string;
    channel: string;
    currency: string;
    preExistingCatalogMapping?: {
        product_mapping: {
            pim_product_identifier: string | null;
            shopify_reference_field: string | null;
        };
        variant_mapping: {
            pim_product_identifier: string | null;
            shopify_reference_field: string | null;
        };
    };
} | null;

export const getConnectorConfig = (currentStoreId: string) =>
    apiFetch<Result>(`${currentStoreId}/configuration/retrieve-pim-config`);
