import { apiFetch } from '../../../../../../util/apiFetch';
import { MediaSource } from '../../../model/MediaSource';
import { ImageMapping } from '../model/ImageMapping';
import { Immutable } from 'immer';
import { AssetMapping } from '../model/AssetMapping';

type SaveMediaMapping = {
    storeId: string;
    mediaSource: MediaSource;
    data: Immutable<ImageMapping | AssetMapping>;
    mappingContext: string;
};

export const saveMediaMapping = (saveMediaMapping: SaveMediaMapping) =>
    apiFetch(
        `${saveMediaMapping.storeId}/mapping/save-media-mapping?mapping_context=${saveMediaMapping.mappingContext}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                source: saveMediaMapping.mediaSource,
                data: saveMediaMapping.data,
            }),
        }
    );
