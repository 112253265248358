import { IconProps } from 'akeneo-design-system';

const ReturnIcon = ({
    title,
    size = 11,
    color = 'currentColor',
    ...props
}: IconProps) => (
    <svg viewBox='0 0 24 24' width={size} height={size} {...props}>
        {title && <title>{title}</title>}
        <g
            id='Icons-/-return'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            <path
                d='M22.7506546,7.3548924 L10.4968147,7.3548924 M19.2793771,3.88361491 L22.7506546,7.3548924 L19.2793771,10.8261699'
                id='Icon'
                stroke='#A1A9B7'
                transform='translate(16.623735, 7.354892) rotate(-45.000000) translate(-16.623735, -7.354892) '
            />
            <polyline
                id='Combined-Shape'
                stroke='#A1A9B7'
                points='21 12.1088486 21 21 3 21 3 3 12.0678749 3'
            />
        </g>
    </svg>
);

export { ReturnIcon };
