import {
    ClientErrorIllustration,
    Link,
    Placeholder,
} from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
    error: Error;
};

export const ClientError = ({ error }: Props) => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<ClientErrorIllustration />}
            size='large'
            title={intl.formatMessage({
                id: 'OV0+sO',
                defaultMessage: 'An Unknown Error Has Occurred',
            })}
        >
            <Link href='/'>
                <FormattedMessage id='fdCS5/' defaultMessage='Reload' />
            </Link>
        </Placeholder>
    );
};
