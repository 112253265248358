import { AssetMapping } from '../model/AssetMapping';
import { ImageMapping } from '../model/ImageMapping';
import { MediaSource } from '../../../model/MediaSource';
import { apiFetch } from '../../../../../../util/apiFetch';
type Result =
    | {
          source: MediaSource.Images;
          attributeCodes: ImageMapping;
      }
    | {
          source: MediaSource.Assets;
          attributeCodes: AssetMapping;
      };

export const getMediaMapping = (storeId: string, mappingContext: string) =>
    apiFetch<Result>(
        `${storeId}/mapping/media-mapping?mapping_context=${mappingContext}`
    ).then((result) => result);
