import { apiFetch } from '../../../../util/apiFetch';

export type Result = {
    families: {
        [code: string]: {
            code: string;
            label: string;
        };
    };
    error?: string;
};

export const getPimFamilies = (currentStoreId: string) =>
    apiFetch<Result>(`${currentStoreId}/mapping/retrieve-pim-families`).then(
        (result) => result
    );
