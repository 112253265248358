import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 0 50px;
`;

type Props = {
    children: React.ReactNode;
};

export const Navigation = ({ children }: Props) => {
    return <Container>{children}</Container>;
};
