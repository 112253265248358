import { Table } from 'akeneo-design-system/lib';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: 10px;
`;

const FixedWidthTableHeaderCell = styled(Table.HeaderCell)`
    min-width: 140px;
`;

const FullWidthTableHeaderCell = styled(Table.HeaderCell)`
    width: 100%;
`;

type Props = {
    children: React.ReactNode;
};

export const SynchronizationList = ({ children }: Props) => (
    <Container>
        <Table>
            <Table.Header>
                <FixedWidthTableHeaderCell>
                    <FormattedMessage id='nso3Mj' defaultMessage='Scope' />
                </FixedWidthTableHeaderCell>
                <FixedWidthTableHeaderCell>
                    <FormattedMessage id='tzMNF3' defaultMessage='Status' />
                </FixedWidthTableHeaderCell>
                <FixedWidthTableHeaderCell>
                    <FormattedMessage id='IuFETn' defaultMessage='Duration' />
                </FixedWidthTableHeaderCell>
                <FixedWidthTableHeaderCell>
                    <FormattedMessage id='+U6ozc' defaultMessage='Type' />
                </FixedWidthTableHeaderCell>
                <FixedWidthTableHeaderCell>
                    <FormattedMessage
                        id='3tyW89'
                        defaultMessage='Reference date'
                    />
                </FixedWidthTableHeaderCell>
                <FixedWidthTableHeaderCell>
                    <FormattedMessage
                        id='7V7VEV'
                        defaultMessage='Next scheduled synchronization'
                    />
                </FixedWidthTableHeaderCell>
                <FullWidthTableHeaderCell></FullWidthTableHeaderCell>
            </Table.Header>
            <Table.Body>
                <>{children}</>
            </Table.Body>
        </Table>
    </Container>
);
