enum AnnouncementTypes {
    ReleaseNote = 'release_note',
    News = 'news',
}

type ReleaseNote = {
    type: AnnouncementTypes.ReleaseNote;
    title: string;
    features: string[];
    link?: string;
    date: string;
};

type News = {
    type: AnnouncementTypes.News;
    title: string;
    description: string;
    link?: string;
    date: string;
};

export { AnnouncementTypes };
export type { ReleaseNote, News };
