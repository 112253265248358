import { Table } from 'akeneo-design-system';
import React from 'react';
import styled from 'styled-components';

const PimAttributeCell = styled(Table.Cell)`
    overflow: initial;
`;

const PimAttributeSelectContainer = styled.div`
    width: 300px;
`;

type Props = {
    pimFamilyLabel: string;
    children: React.ReactNode;
};

export const FamilyMappingItem = ({ pimFamilyLabel, children }: Props) => {
    return (
        <Table.Row data-testid={pimFamilyLabel}>
            <Table.Cell>{pimFamilyLabel}</Table.Cell>
            <PimAttributeCell>
                <PimAttributeSelectContainer>
                    {children}
                </PimAttributeSelectContainer>
            </PimAttributeCell>
        </Table.Row>
    );
};
