import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Container = styled.h1`
    font-family: 'Lato';
    font-style: regular;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #11324d;
    margin-top: 80px;
`;

export const PageTitle = () => {
    return (
        <Container>
            <FormattedMessage
                id='2FLuH9'
                defaultMessage='Association of your Akeneo PIM to Akeneo App Shopify'
            />
        </Container>
    );
};
