import { ClockIcon, DateIcon } from 'akeneo-design-system';
import { DateTime } from 'luxon';
import React from 'react';
import styled, { useTheme } from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Line = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

type Props = {
    dateTime: string;
};

export const DateTimeLabel = ({ dateTime }: Props) => {
    const theme = useTheme();

    return (
        <Container title={dateTime}>
            <Line>
                {React.cloneElement(<DateIcon />, {
                    color: theme.color.grey100,
                    width: 16,
                    height: 16,
                })}
                {DateTime.fromISO(dateTime).toLocaleString(DateTime.DATE_SHORT)}
            </Line>
            <Line>
                {React.cloneElement(<ClockIcon />, {
                    color: theme.color.grey100,
                    width: 16,
                    height: 16,
                })}
                {DateTime.fromISO(dateTime).toLocaleString(
                    DateTime.TIME_WITH_SECONDS
                )}
            </Line>
        </Container>
    );
};
