import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useToaster } from '../../../../util/toast';
import { Immutable } from 'immer';
import { useDispatch } from '../ProductVariant/store/StoreProvider';
import { AttributeMapping } from '../model/AttributeMapping';
import { useSaveVariantMappingForm } from '../ProductVariant/hook/useSaveVariantMapping';
import { isSaveProductVariantMappingValidationError } from '../api/saveProductVariantMapping';

export const useSaveVariantMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const dispatch = useDispatch();
    const saveProductVariantMapping = useSaveVariantMappingForm();

    const [isSaving, setIsSaving] = useState(false);

    const save = async (
        productVariantMapping: Immutable<Map<string, AttributeMapping>>
    ) => {
        setIsSaving(true);
        try {
            await saveProductVariantMapping(productVariantMapping);

            dispatch({ type: 'saveVariantMapping/fulfilled' });

            addToast(
                intl.formatMessage({
                    id: 'c4JWPc',
                    defaultMessage: 'Variant Mapping successfully updated.',
                }),
                'success'
            );
        } catch (error) {
            if (!isSaveProductVariantMappingValidationError(error)) {
                throw error;
            }

            dispatch({
                type: 'saveVariantMapping/rejected',
                pimAttributeErrors: error.data.errors,
            });

            addToast(
                intl.formatMessage({
                    id: 'RJQRyP',
                    defaultMessage:
                        'Mapping not saved. One or more attributes no longer exist in the PIM. Please make sure to update the mapping with valid attributes.',
                }),
                'error'
            );
        }

        setIsSaving(false);
    };

    return { save, isSaving };
};
