import { useIntl } from 'react-intl';
import { useToaster } from '../../../util/toast';
import {
    ShopifyMetafieldDefinitionMapping,
    AttributeMappingByStore,
    saveMetafieldAttributeMapping,
} from './api/saveMetafieldAttributeMapping';
import { useState } from 'react';

export const useSaveMetafieldAttributeMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const [isSaving, setIsSaving] = useState(false);

    const saveMapping = async (
        attributeMapping: AttributeMappingByStore,
        existingProductDefinitionMapping: ShopifyMetafieldDefinitionMapping,
        existingVariantDefinitionMapping: ShopifyMetafieldDefinitionMapping
    ) => {
        setIsSaving(true);

        await saveMetafieldAttributeMapping(
            attributeMapping,
            existingProductDefinitionMapping,
            existingVariantDefinitionMapping
        );

        addToast(
            intl.formatMessage({
                id: 'Lkxe3S',
                defaultMessage: 'Metafield selection has been saved.',
            }),
            'success'
        );

        setIsSaving(false);
    };

    return { saveMapping, isSaving };
};
