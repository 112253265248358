import { Helper, SectionTitle, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Loading } from '../../../../component';
import { useCurrentStore } from '../../../MultiStore';
import { getMetafieldAttributeMapping } from '../api/getMetafieldAttributeMapping';
import { NoMetafieldsFromPimAttributes } from './NoMetafieldsFromPimAttributes';
import { ManageMetafieldsButton } from './ManageMetafieldsButton';
import { getExistingShopifyMetafieldAttributeDefinitions } from '../api/getExistingShopifyMetafieldAttributeDefinitions';
import React, { useState } from 'react';
import { InstabilityStickyHelper } from '../../../../component/PageHeader/InstabilityStickyHelper';
import * as InstabilityType from '../../../../util/InstabilityType';
import {
    MetafieldDefinition,
    MetafieldAttribute as MetafieldAttributeWithMapping,
    ShopifyMetafieldDefinition,
} from '../model/MetafieldAttributeModel';

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: flex-end;
`;

const ColoredLabel = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

export const MetafieldsSelected = () => {
    const { currentStoreId } = useCurrentStore();
    const [shopifyHasInstability, setShopifyHasInstability] =
        useState<boolean>(false);

    const { data: existingShopifyMetafieldsDefinition } = useQuery(
        'getExistingShopifyMetafieldAttributeDefinitions',
        () => getExistingShopifyMetafieldAttributeDefinitions(currentStoreId),
        {
            onSuccess: (existingShopifyMetafieldsDefinition) => {
                if (
                    existingShopifyMetafieldsDefinition &&
                    existingShopifyMetafieldsDefinition.error &&
                    existingShopifyMetafieldsDefinition.error ===
                        InstabilityType.shopify_instability
                ) {
                    setShopifyHasInstability(true);
                }
            },
        }
    );

    const { data: metafieldsWithMapping } = useQuery(
        [currentStoreId, 'getMetafieldAttributeMapping'],
        () => getMetafieldAttributeMapping(currentStoreId),
        {
            enabled:
                undefined !== existingShopifyMetafieldsDefinition &&
                !shopifyHasInstability,
            onSuccess: (retrieveMetafields) => {
                const setMetafieldDefinition = (
                    metafield: MetafieldAttributeWithMapping,
                    metafieldDefinitionId: string | null,
                    metafieldDefinition: MetafieldDefinition,
                    existingDefinitions?: ShopifyMetafieldDefinition[]
                ) => {
                    existingDefinitions?.forEach((existingDefinition) => {
                        // first case, the metafield is mapped with a pre-existing custom field from Shopify
                        if (
                            metafieldDefinitionId &&
                            existingDefinition.id === metafieldDefinitionId
                        ) {
                            metafieldDefinition.fromShopify = true;
                            metafieldDefinition.name = existingDefinition.name;
                            return;
                        }

                        // second case, metafield is created by App into Shopify on synchro
                        if (
                            existingDefinition.namespace === 'akeneo' &&
                            existingDefinition.key ===
                                metafield.code.toLowerCase()
                        ) {
                            metafieldDefinition.fromShopify = false;
                            metafieldDefinition.name = existingDefinition.name;
                            return;
                        }
                    });
                };

                retrieveMetafields.forEach((metafield) => {
                    metafield.productDefinition = {
                        name: null,
                        fromShopify: false,
                    };
                    setMetafieldDefinition(
                        metafield,
                        metafield.shopifyProductDefinitionId,
                        metafield.productDefinition,
                        existingShopifyMetafieldsDefinition?.productDefinitions
                    );

                    metafield.variantDefinition = {
                        name: null,
                        fromShopify: false,
                    };
                    setMetafieldDefinition(
                        metafield,
                        metafield.shopifyVariantDefinitionId,
                        metafield.variantDefinition,
                        existingShopifyMetafieldsDefinition?.variantDefinitions
                    );
                });

                return retrieveMetafields;
            },
        }
    );

    return (
        <>
            <div>
                <Helper level='info'>
                    <FormattedMessage
                        id='IfrZSP'
                        defaultMessage="Metafields synchronized from PIM attributes allow you to list additional product information that will appear on the product page, such as a book's ISBN, a film's release date, or a bottle of wine's vintage. Please use metafields to import any specific field not proposed as a native field in your Shopify store."
                    />
                </Helper>
            </div>
            {metafieldsWithMapping !== undefined ? (
                metafieldsWithMapping.length > 0 ? (
                    <>
                        <SectionTitle>
                            <SectionTitle.Title>
                                <FormattedMessage
                                    id='Wweh9Q'
                                    defaultMessage='Metafields - PIM attributes'
                                />
                            </SectionTitle.Title>
                        </SectionTitle>
                        <ButtonContainer>
                            <ManageMetafieldsButton
                                noMappedMetafields={
                                    metafieldsWithMapping.length <= 0
                                }
                            />
                        </ButtonContainer>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='753yX5'
                                        defaultMessage='Label'
                                    />
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='h2vipu'
                                        defaultMessage='Code'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='+U6ozc'
                                        defaultMessage='Type'
                                    />
                                </Table.HeaderCell>
                                <>
                                    <Table.HeaderCell>
                                        <FormattedMessage
                                            id='ljxo+4'
                                            defaultMessage='Product metafield definition'
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <FormattedMessage
                                            id='98gH6/'
                                            defaultMessage='Variant metafield definition'
                                        />
                                    </Table.HeaderCell>
                                </>
                            </Table.Header>
                            <Table.Body>
                                {metafieldsWithMapping?.map((metafield) => (
                                    <Table.Row key={metafield.code}>
                                        <Table.Cell>
                                            <ColoredLabel>
                                                {metafield.label}
                                            </ColoredLabel>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {metafield.code}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {metafield.attributeTypeLabel}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {
                                                (
                                                    metafield as MetafieldAttributeWithMapping
                                                )?.productDefinition?.name
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {
                                                (
                                                    metafield as MetafieldAttributeWithMapping
                                                )?.variantDefinition?.name
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </>
                ) : (
                    <NoMetafieldsFromPimAttributes />
                )
            ) : shopifyHasInstability ? (
                <InstabilityStickyHelper source='SHOPIFY' />
            ) : (
                <Loading />
            )}
        </>
    );
};
