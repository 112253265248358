import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import logoPath from '../../../asset/images/logo-shopify-app.svg';

const Logo = styled.img`
    display: block;
    margin: auto;
`;

export const ShopifyAssociationLogo = () => {
    const intl = useIntl();

    return (
        <div>
            <Logo
                src={logoPath}
                alt={intl.formatMessage({
                    defaultMessage: 'Akeneo App for Shopify',
                    id: 'GDMAQe',
                })}
            />
        </div>
    );
};
