import { apiFetch } from '../../../../util/apiFetch';

type Result = {
    attributeMappings: {
        [shopifyAttributeCode: string]:
            | {
                  shopifyAttributeCode: string;
                  hasAttributePerFamily: false;
                  hasCollectionOfAttribute: false;
                  data: string;
              }
            | {
                  shopifyAttributeCode: string;
                  hasAttributePerFamily: true;
                  hasCollectionOfAttribute: false;
                  data: { [pimFamilyCode: string]: string | null };
              }
            | {
                  shopifyAttributeCode: string;
                  hasAttributePerFamily: false;
                  hasCollectionOfAttribute: true;
                  data: string[];
              }
            | {
                  shopifyAttributeCode: string;
                  hasAttributePerFamily: true;
                  hasCollectionOfAttribute: true;
                  data: {
                      [pimFamilyCode: string]: string[] | null;
                  };
              };
    };
};

export const getVariantMapping = (currentStoreId: string) =>
    apiFetch<Result>(`${currentStoreId}/mapping/retrieve-variant-mapping`).then(
        (result) => (result ? result.attributeMappings : {})
    );
