import { Button, Helper, Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { ShopifyAttribute } from '../../../model/ShopifyAttribute';
import { MediaMappingState } from '../../../Product/MediaMapping/component/MediaMappingState';
import { MediaMappingVariantState } from '../../MediaMapping/component/MediaMappingVariantState';
import { selectAttributeMapping } from '../../selector/selectAttributeMapping';
import { useSelector } from '../../store/StoreProvider';
import { MappingState } from './MappingState';
import { FormattedPimAttributeTypes } from '../../../Product/component/FormattedPimAttributeTypes';

const ShopifyAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

const PimAttributeCell = styled(Table.Cell)`
    overflow: initial;
`;

const PimAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 300px;
`;

const ButtonContainer = styled.div`
    text-align: right;
    width: 100%;
`;

type Props = {
    shopifyAttribute: ShopifyAttribute;
    children: React.ReactNode;
};

export const MappingItem = ({ shopifyAttribute, children }: Props) => {
    const navigate = useNavigate();

    const attributeMapping = useSelector(
        selectAttributeMapping(shopifyAttribute.code)
    );

    return (
        <Table.Row data-testid={shopifyAttribute.code}>
            <Table.Cell>
                <ShopifyAttributeContainer>
                    <div>
                        {shopifyAttribute.label}
                        {shopifyAttribute.isRequired && (
                            <i>
                                &nbsp;(
                                <FormattedMessage
                                    id='Seanpx'
                                    defaultMessage='Required'
                                />
                                )
                            </i>
                        )}
                    </div>
                    <Helper inline level='info'>
                        <FormattedMessage
                            id='FkoXkx'
                            defaultMessage='Compatible PIM attribute {countTypes, plural, one {type} other {types}}: {types}'
                            values={{
                                countTypes:
                                    shopifyAttribute.pimAllowedTypes.length,
                                types: (
                                    <FormattedPimAttributeTypes
                                        types={[
                                            ...shopifyAttribute.pimAllowedTypes,
                                        ]}
                                    />
                                ),
                            }}
                        />
                        {shopifyAttribute.constraint && (
                            <>
                                <br />
                                <FormattedMessage
                                    id='FpF8hi'
                                    defaultMessage='Limitation: {constraint}'
                                    values={{
                                        constraint: shopifyAttribute.constraint,
                                    }}
                                />
                            </>
                        )}
                    </Helper>
                </ShopifyAttributeContainer>
            </Table.Cell>

            <PimAttributeCell>
                <PimAttributeContainer>{children}</PimAttributeContainer>
            </PimAttributeCell>

            <Table.Cell>
                {attributeMapping.shopifyAttributeCode === 'IMAGES' && (
                    <MediaMappingState />
                )}
                {attributeMapping.shopifyAttributeCode === 'VARIANT_IMAGE' && (
                    <MediaMappingVariantState
                        attributeMapping={attributeMapping.shopifyAttributeCode}
                    />
                )}
                {attributeMapping.shopifyAttributeCode !== 'IMAGES' &&
                    attributeMapping.shopifyAttributeCode !==
                        'VARIANT_IMAGE' && (
                        <MappingState shopifyAttribute={shopifyAttribute} />
                    )}
            </Table.Cell>

            <Table.Cell>
                <ButtonContainer>
                    {attributeMapping.hasAttributePerFamily && (
                        <Button
                            ghost
                            level='secondary'
                            onClick={() => navigate(shopifyAttribute.code)}
                        >
                            <FormattedMessage
                                id='SUOxRz'
                                defaultMessage='Manage family mapping'
                            />
                        </Button>
                    )}
                </ButtonContainer>
            </Table.Cell>
        </Table.Row>
    );
};
