import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const DividerContainer = styled.div`
    flex: 0 1 auto;
    margin: 0 100px 0;
`;

const FirstLineContainer = styled.div`
    border-left: 1px solid #c7cbd4;
    height: 68px;
    transform: translateX(50%);
`;

const SecondLineContainer = styled.div`
    border-left: 1px solid #c7cbd4;
    height: 65%;
    transform: translateX(50%);
`;

const TextContainer = styled.div`
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    padding: 15px;
`;

export const PanelDivider = () => {
    return (
        <DividerContainer>
            <FirstLineContainer />
            <TextContainer>
                <FormattedMessage id='INlWvJ' defaultMessage='OR' />
            </TextContainer>
            <SecondLineContainer />
        </DividerContainer>
    );
};
