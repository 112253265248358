import { Button } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';

type Props = {
    onClick: () => void;
};
export const EditMarketsPricingButton = ({ onClick }: Props) => (
    <Button level='secondary' ghost={true} onClick={onClick}>
        <FormattedMessage id='9Mc9DC' defaultMessage='Edit Markets pricing' />
    </Button>
);
