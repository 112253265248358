import { ClientErrorIllustration, Placeholder } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export const BadRequest = () => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<ClientErrorIllustration />}
            size='large'
            title={intl.formatMessage({
                id: 'T0PESc',
                defaultMessage: '400 Bad Request',
            })}
        />
    );
};
