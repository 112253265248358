import { placeholderStyle, Table } from 'akeneo-design-system';
import styled from 'styled-components';

const TableSkeleton = styled(Table)`
    border-spacing: 0 10px;
    border-collapse: separate;
`;

TableSkeleton.Cell = styled(Table.Cell)`
    ${placeholderStyle}
`;

export { TableSkeleton };
