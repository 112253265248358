import {
    CloseIcon,
    IconButton,
    SelectInput,
    Table,
} from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const AttributeSelectContainer = styled.div`
    width: 300px;
`;

const RemoveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

type Props = {
    attributes: Array<{ code: string; label: string }>;
    value: string | null;
    onChange: (value: string | null) => void;
};

export const ImageAttributeItem = ({ attributes, value, onChange }: Props) => {
    const intl = useIntl();

    const handleChange = (value: string | null) => onChange(value);

    const handleRemoveAttribute = () => onChange(null);

    return (
        <>
            <Table.Cell>
                <AttributeSelectContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'rL2QMp',
                            defaultMessage: 'No image attribute found',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'lcPgRZ',
                            defaultMessage: 'Select an image attribute',
                        })}
                        value={value}
                        openLabel={''}
                        onChange={handleChange}
                        clearable={false}
                    >
                        {attributes.map((attribute) => (
                            <SelectInput.Option
                                key={attribute.code}
                                value={attribute.code}
                            >
                                {attribute.label}
                            </SelectInput.Option>
                        ))}
                    </SelectInput>
                </AttributeSelectContainer>
            </Table.Cell>
            <Table.Cell>
                <RemoveButtonContainer>
                    <IconButton
                        icon={<CloseIcon />}
                        title={intl.formatMessage({
                            id: 'S53Eji',
                            defaultMessage: 'Remove attribute',
                        })}
                        onClick={handleRemoveAttribute}
                        disabled={!value}
                        ghost='borderless'
                        level='tertiary'
                    />
                </RemoveButtonContainer>
            </Table.Cell>
        </>
    );
};
