import { getColor, getFontSize } from 'akeneo-design-system';
import React from 'react';
import styled from 'styled-components';

type Props = {
    date: string;
    title: string;
    children: React.ReactNode;
};

const AnnouncementHeader = ({ date, title, children }: Props) => {
    return (
        <>
            <Header>
                {children}
                <Date>{date}</Date>
            </Header>
            <Title>{title}</Title>
        </>
    );
};

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const Date = styled.span`
    color: ${getColor('grey', 100)};
`;

const Title = styled.div`
    font-size: ${getFontSize('bigger')};
    color: ${getColor('brand', 100)};
    font-weight: bold;
    margin-bottom: 10px;
`;

export { AnnouncementHeader };
