import {
    Field,
    Helper,
    Link,
    SectionTitle,
    SelectInput,
    Tag,
    Tags,
    TextInput,
} from 'akeneo-design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Skeleton } from '../../../component';
import { getConnectorConfigOptions } from '../api/getConnectorConfigOptions';
import { ConnectorConfig } from '../model/ConnectorConfig';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { useCurrentStore } from '../../MultiStore';
import { helpCenterConfigurationUrl } from '../../../util/config';
import Hotjar from '@hotjar/browser';
import { getHotjarParameters } from '../api/getHotjarParameters';
import { EditSecondaryLocalesMappingButton } from './EditSecondaryLocalesMappingButton';
import { useNavigate } from 'react-router-dom';
import { PreExistingCatalogMapping } from './PreExistingCatalogMapping';
import { InstabilityStickyHelper } from '../../../component/PageHeader/InstabilityStickyHelper';
import * as InstabilityType from '../../../util/InstabilityType';
import { EditMarketsPricingButton } from './EditMarketsPricingButton';

import { State } from '../store/reducer';

const defaultConnectorConfig: ConnectorConfig = {
    channel: null,
    locale: null,
    categoryCode: '',
    currency: null,
    preExistingCatalogMapping: {
        product_shopify_reference_field: null,
        product_pim_product_identifier: null,
        variant_shopify_reference_field: null,
        variant_pim_product_identifier: null,
    },
};

const configFormIsDirty = (state: State) => state.connectorConfigForm.isDirty;

export const ConnectorConfigForm = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);
    const isDirty = useSelector(configFormIsDirty);

    useQuery('get-hotjar-parameters', getHotjarParameters, {
        onSuccess: (hotjarParameters) => {
            if (
                null !== hotjarParameters.siteId &&
                null !== hotjarParameters.version
            ) {
                Hotjar.init(hotjarParameters.siteId, hotjarParameters.version);
            }
        },
    });

    const { currentStoreId } = useCurrentStore();

    const { connectorConfigOptions, connectorConfig, errors } = useSelector(
        (state) => ({
            connectorConfigOptions: state.connectorConfigOptions,
            connectorConfig:
                state.connectorConfigForm.data || defaultConnectorConfig,
            errors: state.connectorConfigForm.errors,
        })
    );

    const { isFetching } = useQuery(
        'Configuration/getConnectorConfigOptions',
        () => getConnectorConfigOptions(currentStoreId),
        {
            onSuccess: (connectorConfigOptions) => {
                if (
                    connectorConfigOptions.error &&
                    connectorConfigOptions.error ===
                        InstabilityType.pim_instability
                ) {
                    setPimHasInstability(true);
                }

                return dispatch({
                    type: 'getConnectorConfigOptions/fulfilled',
                    connectorConfigOptions,
                });
            },
        }
    );

    const handleChange = (connectorConfig: ConnectorConfig) =>
        dispatch({
            type: 'connectorConfigForm/changed',
            connectorConfig,
        });

    const requiredLabel = intl.formatMessage({
        id: 'VG94fP',
        defaultMessage: '(required)',
    });

    const mapError = (errors: readonly string[]) =>
        errors.map((error, idx) => (
            <Helper key={idx} inline level='error'>
                {error}
            </Helper>
        ));

    return (
        <>
            {pimHasInstability && <InstabilityStickyHelper source='PIM' />}
            <SectionTitle>
                <Tags>
                    <Tag tint='purple'>Akeneo</Tag>
                </Tags>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='+KCEr2'
                        defaultMessage='Store configuration'
                    />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='X8qWvC'
                    defaultMessage='To set up your Akeneo PIM sources,'
                />
                &nbsp;
                <Link href={helpCenterConfigurationUrl} target='_blank'>
                    <FormattedMessage
                        id='+z9ImG'
                        defaultMessage='check out our Help Center for more information.'
                    />
                </Link>
            </Helper>

            <br />

            <form>
                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: 'KeO51o',
                            defaultMessage: 'Channel',
                        })}
                        requiredLabel={requiredLabel}
                        fullWidth={true}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: '6uhSSw',
                                defaultMessage: 'Select a channel',
                            })}
                            openLabel=''
                            value={connectorConfig.channel}
                            data-testid='pim-channel'
                            onChange={(channel: string | null) =>
                                handleChange({
                                    ...connectorConfig,
                                    channel,
                                })
                            }
                            invalid={!!errors?.channel}
                            emptyResultLabel={intl.formatMessage({
                                id: '2zikCs',
                                defaultMessage: 'No channel found',
                            })}
                        >
                            {Object.entries(
                                connectorConfigOptions?.channelOptions || {}
                            ).map(([code, label]) => (
                                <SelectInput.Option key={code} value={code}>
                                    {label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {errors?.channel && mapError(errors.channel)}
                        {!pimHasInstability &&
                            connectorConfig.channel !== null &&
                            connectorConfig.channel !== '' &&
                            !Object.keys(
                                connectorConfigOptions?.channelOptions || {}
                            ).some(
                                (code) => code === connectorConfig.channel
                            ) && (
                                <Helper inline level='warning'>
                                    <FormattedMessage
                                        id='P74+Gj'
                                        defaultMessage='The channel is not available anymore'
                                    />
                                </Helper>
                            )}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}

                <br />

                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: '3cWhoI',
                            defaultMessage: 'Main locale',
                        })}
                        requiredLabel={requiredLabel}
                        fullWidth={true}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: 'cd6mK/',
                                defaultMessage: 'Select a main locale',
                            })}
                            openLabel=''
                            value={connectorConfig.locale}
                            data-testid='pim-locale'
                            onChange={(locale: string | null) =>
                                handleChange({
                                    ...connectorConfig,
                                    locale,
                                })
                            }
                            invalid={!!errors?.locale}
                            emptyResultLabel={intl.formatMessage({
                                id: 'hST08B',
                                defaultMessage: 'No locale found',
                            })}
                            clearable={false}
                        >
                            {Object.entries(
                                connectorConfigOptions?.localeOptions || {}
                            ).map(([code, label]) => (
                                <SelectInput.Option key={code} value={code}>
                                    {label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {errors?.locale && mapError(errors.locale)}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}
                {!isFetching &&
                    connectorConfig.locale &&
                    connectorConfig.channel && (
                        <div>
                            <br />
                            <EditSecondaryLocalesMappingButton
                                onClick={() => navigate('secondary-locales')}
                            />
                        </div>
                    )}

                <br />
                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: 'AwUpx3',
                            defaultMessage: 'Main currency',
                        })}
                        requiredLabel={requiredLabel}
                        fullWidth={true}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: 'fdLM0l',
                                defaultMessage: 'Select a currency',
                            })}
                            openLabel=''
                            value={connectorConfig.currency}
                            data-testid='pim-currency'
                            onChange={(currency: string | null) =>
                                handleChange({
                                    ...connectorConfig,
                                    currency,
                                })
                            }
                            invalid={!!errors?.currency}
                            emptyResultLabel={intl.formatMessage({
                                id: 'zzYiAk',
                                defaultMessage: 'No currency found',
                            })}
                        >
                            {Object.entries(
                                connectorConfigOptions?.currencyOptions || {}
                            ).map(([code, label]) => (
                                <SelectInput.Option key={code} value={code}>
                                    {label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {errors?.currency && mapError(errors.currency)}
                        {!pimHasInstability &&
                            connectorConfig.currency !== null &&
                            connectorConfig.currency !== '' &&
                            !Object.keys(
                                connectorConfigOptions?.currencyOptions || {}
                            ).some(
                                (code) => code === connectorConfig.currency
                            ) && (
                                <Helper inline level='warning'>
                                    <FormattedMessage
                                        id='7bkLHK'
                                        defaultMessage='The currency is not available anymore'
                                    />
                                </Helper>
                            )}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}
                {!isFetching &&
                    !isDirty &&
                    connectorConfig.currency &&
                    connectorConfig.channel && (
                        <div>
                            <br />
                            <EditMarketsPricingButton
                                onClick={() => navigate('markets-pricing')}
                            />
                        </div>
                    )}

                <br />

                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            defaultMessage: 'Category code',
                            id: '2rbEKt',
                        })}
                        requiredLabel={requiredLabel}
                        fullWidth={true}
                    >
                        <TextInput
                            required={true}
                            value={connectorConfig.categoryCode}
                            onChange={(categoryCode: string) =>
                                handleChange({
                                    ...connectorConfig,
                                    categoryCode,
                                })
                            }
                            invalid={!!errors?.categoryCode}
                        />
                        <Helper level='info'>
                            <FormattedMessage
                                defaultMessage='Please paste the code of the category you want to synchronize.'
                                id='XgONbF'
                            />
                        </Helper>
                        {errors?.categoryCode && mapError(errors.categoryCode)}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}

                <br />
            </form>

            <PreExistingCatalogMapping />
        </>
    );
};

const LabelSkeleton = styled(Skeleton)`
    height: 20px;
    margin-bottom: 10px;
    width: 25%;
`;

const FieldSkeleton = styled(Skeleton)`
    height: 40px;
`;
