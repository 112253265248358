import { apiFetch } from '../../../util/apiFetch';
import { SynchronizationHistoryStatus } from '../../Synchronization/model/SynchronizationHistoryStatus';

type Result = {
    stores: Array<Store>;
};

export type Store = {
    id: string;
    name: string;
    shop: string;
    status: string;
    lastSynchronizationImport: string | null;
    lastSynchronizationStatus: SynchronizationHistoryStatus | null;
};

export const getStores = () =>
    apiFetch<Result>('store/retrieve-stores').then((result) => {
        return result.stores;
    });
