import {
    CloseIcon,
    getColor,
    MegaphoneIcon,
    SectionTitle,
} from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { keyframes } from 'styled-components';

type Props = {
    onCloseAnnouncements: () => void;
    children: React.ReactNode;
};

const SidePanel = ({ onCloseAnnouncements, children }: Props) => {
    return (
        <>
            <Overlay onClick={onCloseAnnouncements} />
            <NewsPanel>
                <SectionTitleStyled>
                    <SectionTitleTextStyled>
                        <MegaphoneIconThemed size={20} />
                        <FormattedMessage
                            id='3kP3Of'
                            defaultMessage='Announcements'
                        />
                    </SectionTitleTextStyled>
                    <SectionTitle.Spacer />
                    <CloseIconThemed size={16} onClick={onCloseAnnouncements} />
                </SectionTitleStyled>
                {children}
            </NewsPanel>
        </>
    );
};

const CloseIconThemed = styled(CloseIcon)`
    color: ${getColor('brand', 100)};
    cursor: pointer;
`;

const MegaphoneIconThemed = styled(MegaphoneIcon)`
    color: ${getColor('brand', 100)};
`;

const SectionTitleStyled = styled(SectionTitle)`
    border-bottom: 1px solid ${getColor('brand', 100)};
    margin-bottom: 30px;
`;

const SectionTitleTextStyled = styled(SectionTitle.Title)`
    color: ${getColor('brand', 100)};
    display: flex;
    align-items: center;
    gap: 5px;
`;

const Overlay = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.14);
    z-index: 999;
    left: 0;
    top: 0;
`;

const displayAnimation = keyframes`
  0% {right: -400px}
  100% {right: 0}
`;

const NewsPanel = styled.div`
    width: 400px;
    padding: 30px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    background: #fff;
    animation: ${displayAnimation} 0.3s ease-in-out;
    z-index: 999;
`;

export { SidePanel };
