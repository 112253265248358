import { Button } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useResetConfiguration } from './hook/useResetConfiguration';
import React, { useState } from 'react';
import { DataCleanupConfirmationModal } from './component/DataCleanupConfirmationModal';

const ButtonContainer = styled.div`
    padding-left: 10px;
`;

export const ResetConfigurationButton = () => {
    const { reset, isResetting } = useResetConfiguration();

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleReset = async () => {
        if (!showConfirmation) {
            setShowConfirmation(true);

            return;
        }

        await reset();
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    return (
        <ButtonContainer>
            <Button
                level={'danger'}
                disabled={isResetting}
                onClick={handleReset}
            >
                <FormattedMessage id='jm/spn' defaultMessage='Reset' />
            </Button>
            {showConfirmation && (
                <DataCleanupConfirmationModal
                    onConfirm={handleReset}
                    onCancel={handleCancel}
                />
            )}
        </ButtonContainer>
    );
};
