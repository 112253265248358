import React, { createContext, useContext, useState } from 'react';

type Context = {
    currentStoreId: string | null;
    setCurrentStoreId: (storeId: string | null) => void;
};

export const CurrentStoreIdContext = createContext<Context | null>(null);

type Props = {
    children: React.ReactNode;
};

export const CurrentStoreProvider = ({ children }: Props) => {
    const [currentStoreId, setCurrentStoreId] = useState<string | null>(null);

    return (
        <CurrentStoreIdContext.Provider
            value={{ currentStoreId, setCurrentStoreId }}
        >
            {children}
        </CurrentStoreIdContext.Provider>
    );
};

export const useCurrentStore = () => {
    const context = useContext(CurrentStoreIdContext);
    if (null === context) {
        throw new Error('<CurrentStoreProvider /> not setup');
    }

    if (context.currentStoreId === null) {
        throw new Error('Current store ID is not set');
    }

    return { currentStoreId: context.currentStoreId };
};
