import { connectorTheme, Theme } from 'akeneo-design-system';

const appTheme: Theme = {
    ...connectorTheme,
    name: 'App',
    color: {
        ...connectorTheme.color,
        brand100: '#4f5bc6',
    },
};

export { appTheme };
