import styled from 'styled-components';
import { DetailedReportCounter } from './DetailedReportCounter';

const DetailedBlock = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 6px 0 6px 0;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    border-color: #c7cbd4;
    height: 50%;
    width: 400px;
`;

const Icon = styled.div`
    padding: 19px 25px;
`;

const DetailedBlockContent = styled.div`
    border-left: 1px solid #e8ebee;
    padding: 6px 20px 6px 20px;
`;

const Grey = styled.b`
    color: ${({ theme }) => theme.color.grey140};
`;

const ContentBlock = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    color: ${({ theme }) => theme.color.grey100};
`;

type Props = {
    title: React.ReactNode;
    icon: React.ReactNode;
    detailedReport: {
        created?: number;
        updated?: number;
        errors?: number;
        processed?: number;
    };
};

export const DetailedReportBlock = ({ title, icon, detailedReport }: Props) => {
    return (
        <>
            <DetailedBlock>
                <Icon>{icon}</Icon>
                <DetailedBlockContent>
                    <Grey>{title}</Grey>

                    <ContentBlock>
                        <DetailedReportCounter
                            detailedReport={detailedReport}
                        />
                    </ContentBlock>
                </DetailedBlockContent>
            </DetailedBlock>
        </>
    );
};
