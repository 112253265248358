import { apiFetch } from '../../../../util/apiFetch';

type Result = {
    pimAttributes: {
        code: string;
        type: string;
        label: string;
        attributeTypeLabel: string;
    }[];
    error?: string;
} | null;

export const getPimAttributesForMetafieldAttributeMapping = (storeId: string) =>
    apiFetch<Result>(
        `${storeId}/metafield/get-pim-attributes-for-metafield-attribute-mapping`
    ).then((result) => result);
