import React from 'react';
import styled from 'styled-components';
import { Synchronization } from './Execution/Synchronization';
import { initialState } from './store/reducer';
import { StoreProvider } from './store/StoreProvider';
import { History } from './History/History';
import { Route, Routes } from 'react-router-dom';
import { SynchronizationReport } from '../SynchronizationReport';
import { DetailedModalReport } from '../SynchronizationReport/DetailedModalReport/DetailedModalReport';

const Container = styled.div`
    margin-top: 32px;
`;

export const SynchronizationTab = () => {
    return (
        <Container>
            <StoreProvider initialState={initialState}>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <>
                                <Synchronization />
                                <History />
                            </>
                        }
                    />
                    <Route
                        path='synchronization-report/:synchronization'
                        element={<SynchronizationReport />}
                    />
                    <Route
                        path='synchronization-report/:synchronization/detailed-logs'
                        element={<DetailedModalReport />}
                    />
                </Routes>
            </StoreProvider>
        </Container>
    );
};
