import { useQuery } from 'react-query';
import { isValidPim } from '../api/isValidPim';

export const isValidPimKey = 'Authentication/isValidPim';

export const useIsValidPim = (pimUrl: string) => {
    return useQuery(isValidPimKey, () => isValidPim(pimUrl), {
        onSuccess: (isValid: boolean) => {
            console.log(isValid);
        },
    });
};
