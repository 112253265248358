import React from 'react';
import { News } from '../model/Announcement';
import styled from 'styled-components';
import { getColor, Tag, Tags } from 'akeneo-design-system';
import { AnnouncementHeader } from './AnnouncementHeader';
import { ReadMore } from './ReadMore';
import { FormattedMessage } from 'react-intl';

type Props = {
    data: News;
};

const NewsItem = ({ data }: Props) => {
    return (
        <Container>
            <AnnouncementHeader date={data.date} title={data.title}>
                <Tags>
                    <Tag tint='green'>
                        <FormattedMessage id='NZyhSX' defaultMessage='News' />
                    </Tag>
                </Tags>
            </AnnouncementHeader>
            <Description>{data.description}</Description>
            {data.link !== undefined && <ReadMore link={data.link} />}
        </Container>
    );
};

const Container = styled.div`
    border-bottom: 1px solid ${getColor('grey', 80)};
    margin-bottom: 20px;
    padding-bottom: 20px;
`;

const Description = styled.div`
    color: ${getColor('grey', 140)};
`;

export { NewsItem };
