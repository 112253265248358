import { Helper, SelectInput } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import { ShopifyAttribute } from '../../model/ShopifyAttribute';
import { selectAllowedPimAttributes } from '../selector/selectAllowedPimAttributes';
import { useSelector } from '../store/StoreProvider';
import { PimAttributeErrors } from '../../model/PimAttributeErrors';
import React from 'react';

type Props = {
    shopifyAttribute: ShopifyAttribute;
    selectedPimAttributeCode: string | null;
    pimFamilyCode?: string;
    disabled?: boolean;
    pimAttributeErrors?: PimAttributeErrors | null;
    onChange: (pimAttributeCodes: string | null) => void;
};

export const AllowedPimAttributeSelect = ({
    shopifyAttribute,
    selectedPimAttributeCode,
    pimFamilyCode,
    disabled,
    onChange,
    pimAttributeErrors,
}: Props) => {
    const intl = useIntl();

    const allowedPimAttributes = useSelector(
        selectAllowedPimAttributes(shopifyAttribute, pimFamilyCode)
    );

    const mapError = (errors: readonly string[] | undefined) =>
        undefined !== errors &&
        errors.map((error, idx) => (
            <Helper key={idx} inline level='error'>
                {error}
            </Helper>
        ));

    return (
        <>
            <SelectInput
                clearable={true}
                emptyResultLabel=''
                onChange={onChange}
                placeholder={intl.formatMessage({
                    id: '+VTndF',
                    defaultMessage: 'Select an Akeneo PIM attribute',
                })}
                openLabel=''
                value={selectedPimAttributeCode}
                readOnly={disabled}
                invalid={
                    pimAttributeErrors && selectedPimAttributeCode
                        ? !!pimAttributeErrors[selectedPimAttributeCode]
                        : false
                }
            >
                {allowedPimAttributes.map((pimAttribute) => (
                    <SelectInput.Option
                        key={pimAttribute.code}
                        value={pimAttribute.code}
                    >
                        {pimAttribute.label}
                    </SelectInput.Option>
                ))}
            </SelectInput>
            {pimAttributeErrors &&
                selectedPimAttributeCode &&
                pimAttributeErrors[selectedPimAttributeCode] &&
                mapError(pimAttributeErrors[selectedPimAttributeCode])}
        </>
    );
};
