import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, getColor } from 'akeneo-design-system';
import styled from 'styled-components';
import logoPath from '../../../asset/images/akeneo-logo-long.png';
import { akeneoPimDemoUrl } from '../../../util/config';

const Container = styled.div`
    flex: 1;
    text-align: center;
    position: relative;
`;
const Logo = styled.img`
    margin-right: 50px;
`;

const PanelTitleContainer = styled.div`
    color: ${getColor('purple', 100)};
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    margin-top: 30px;
`;

const PanelDescriptionContainer = styled.div`
    flex: 1 1 auto;
    text-align: center;
    margin-top: 30px;
`;

const FormButtonContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`;

export const AssociationRightPanel = () => {
    const intl = useIntl();

    const handleRedirect = () => {
        window.location.replace(akeneoPimDemoUrl);
    };

    return (
        <Container>
            <Logo
                src={logoPath}
                alt={intl.formatMessage({
                    defaultMessage: 'Akeneo App for Shopify',
                    id: 'GDMAQe',
                })}
            />
            <PanelTitleContainer>
                <FormattedMessage
                    id='GTPASH'
                    defaultMessage="You don't have Akeneo PIM"
                />
            </PanelTitleContainer>
            <PanelDescriptionContainer>
                <FormattedMessage
                    id='4yqudx'
                    defaultMessage='You must first have a PIM and have subscribed to the Akeneo App for Shopify {br}to use it. To get a demo of the Akeneo PIM, please make an appointment by clicking on "Request demo".'
                    values={{ br: <br /> }}
                />
            </PanelDescriptionContainer>
            <FormButtonContainer>
                <Button level='primary' onClick={handleRedirect}>
                    <FormattedMessage
                        id='YRwVgd'
                        defaultMessage='REQUEST A DEMO'
                    />
                </Button>
            </FormButtonContainer>
        </Container>
    );
};
