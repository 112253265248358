import { apiFetch } from '../../../util/apiFetch';

type Result = {
    isRunning: string;
};

export const isSynchronizationRunning = async (currentStoreId: string) =>
    apiFetch<Result>(
        `${currentStoreId}/synchronization/is-a-synchronization-running`
    ).then((result) => {
        return result.isRunning;
    });
